import { Box, Image, Text } from '@chakra-ui/react';
import { gsap } from 'gsap';
import { useEffect, useRef } from 'react';
import {
  Accordion,
  AccordionItem,
  AccordionButton,
  AccordionPanel,
} from '@chakra-ui/react';
import React from 'react';

export const Faqs = () => {
  const faqDetails = [
    {
      id: 1,
      question: ' What equipment do you provide maintenance services for?',
      answer: 'We maintain generators, air conditioners, bulldozers, and more.',
    },
    {
      id: 2,
      question: 'How often should equipment be serviced?',
      answer:
        "Follow the manufacturer's guidelines or consult with us for a personalized schedule.",
    },
    {
      id: 3,
      question: 'What are the benefits of regular maintenance?',
      answer:
        'Optimize performance, reduce downtime, extend lifespan, and ensure safety.',
    },
    {
      id: 4,
      question: 'Do you offer emergency maintenance services?',
      answer:
        'Yes, we provide emergency maintenance services to minimize downtime.',
    },
    {
      id: 5,
      question: 'How experienced are your technicians?',
      answer:
        'Our technicians are highly trained and experienced professionals.',
    },
    {
      id: 6,
      question: 'What does your maintenance service include?',
      answer:
        'Thorough inspections, routine maintenance, component replacements, and more.',
    },
    {
      id: 7,
      question: 'How can I schedule a maintenance service?      ',
      answer:
        'Contact us through our website or phone to schedule an appointment.',
    },
    {
      id: 7,
      question: 'Do you offer maintenance plans or contracts?',
      answer: ' Yes, we offer customized maintenance plans and contracts.',
    },
    {
      id: 7,
      question: 'How do you handle equipment repairs?',
      answer: 'Our technicians diagnose and repair equipment as needed.',
    },
    {
      id: 7,
      question: 'What if I need assistance or have more questions?',
      answer: 'Our dedicated support team is available for ongoing assistance.',
    },
  ];
  const faqs = useRef();
  const general = useRef();

  useEffect(() => {
    const general = document.getElementById('#general');
    // faq
    gsap.fromTo(
      faqs.current.children,
      {
        // y: -10,
        opacity: 0,
      },
      {
        y: 0,
        opacity: 1,
        stagger: 0.05,
        duration: 0.3,
      }
    );
    gsap.fromTo(
      general,
      {
        opacity: 0,
      },
      {
        opacity: 1,
        stagger: 0.1,
        duration: 0.2,
        scrollTrigger: {
          trigger: general,
        },
      }
    );
  }, []);

  return (
    <Box
      w={'full'}
      maxW={'1920px'}
      display={'flex'}
      flexDir={{ base: 'column', md: 'row' }}
      py={{ base: '17.5vw', md: '3.75vw', '3xl': '4.5rem' }}
      pb={{ base: '20.3125vw', md: '3.75vw', '3xl': '4.5rem' }}
      px={{ base: '6.25vw', md: '5.45vw', '3xl': '6.5625rem' }}
      alignItems={'start'}
      justifyContent={'space-between'}
      gap={{ base: '15vw', md: '0' }}
    >
      <Box
        display={'flex'}
        flexDirection={'column'}
        gap={{ base: '0.625vw', md: '0.15vw', '3xl': '0.25rem' }}
        w={{ md: '30vw', '3xl': '36rem' }}
      >
        <Text
          fontFamily={'Montserrat'}
          fontWeight={'500'}
          fontSize={{ base: '5.73125vw', md: '2.275vw', '3xl': '2.75rem' }}
          lineHeight={{ base: '11.359vw', md: '4.54vw', '3xl': '5.4375rem' }}
          color={'#000938'}
        >
          {`General FAQs`.split('').map((item, index) => {
            return (
              <Text as={'span'} key={index} id="general" ref={general}>
                {item}
              </Text>
            );
          })}
        </Text>
        <Text
          fontWeight={'400'}
          fontSize={{ base: '3.85625vw', md: '1.0417vw', '3xl': '1.25rem' }}
          lineHeight={{ base: '5vw', md: '1.4vw', '3xl': '1.685rem' }}
          color={'#000938'}
          fontFamily="Source Sans Pro"
        >
          Everything you need to know about the service and how it works. Find
          the answer to all of our most frequently asked questions.
        </Text>
      </Box>
      <Box pb={{ md: '1.25vw', '3xl': '1.75rem' }}>
        <Accordion
          display={'flex'}
          flexDirection={'column'}
          allowToggle
          defaultIndex={[0]}
          gap={{ base: '4.2375vw', md: '1.8vw', '3xl': '2.188rem' }}
          ref={faqs}
        >
          {faqDetails.map(details => {
            return (
              <AccordionItem
                key={details.id}
                borderColor={'#000'}
                borderTop={'1px'}
                borderBottom="0px"
                p={'0'}
                pt={{ base: '4.2vw', md: '1.775vw', '3xl': '2.125rem' }}
                w={{ md: '37.75vw', '3xl': '45.3125rem' }}
              >
                {({ isExpanded }) => (
                  <>
                    <h2>
                      <AccordionButton
                        p={'0'}
                        fontFamily="Source Sans Pro"
                        _focus={{ backgroundColor: 'transparent' }}
                        _hover={{ backgroundColor: 'transparent' }}
                        color={'black'}
                      >
                        <Box
                          as="span"
                          fontFamily={'Montserrat'}
                          flex="1"
                          color={'#000938'}
                          fontWeight={'500'}
                          fontSize={{
                            base: '4.74vw',
                            md: '1.355vw',
                            '3xl': '1.625rem',
                          }}
                          lineHeight={{
                            base: '6.55vw',
                            md: '1.825vw',
                            '3xl': '2.246rem',
                          }}
                          textAlign="left"
                          letterSpacing={'0.019rem'}
                        >
                          {details.question}
                        </Box>
                        {isExpanded ? (
                          <Image
                            src="/faq/minusIcon.png"
                            w={{
                              base: '4.0625vw',
                              md: '1.8vw',
                              '3xl': '2.15rem',
                            }}
                          />
                        ) : (
                          <Image
                            src="/faq/addIcon.png"
                            w={{
                              base: '4.0625vw',
                              md: '1.8vw',
                              '3xl': '2.15rem',
                            }}
                          />
                        )}
                      </AccordionButton>
                    </h2>
                    <AccordionPanel
                      p={'0'}
                      fontFamily="Source Sans Pro"
                      color="#464E77"
                      letterSpacing={'0.031rem'}
                      fontSize={{
                        base: '3.628vw',
                        md: '1.15vw',
                        '3xl': '1.375rem',
                      }}
                      lineHeight={{
                        base: '6.103vw',
                        md: '1.925vw',
                        '3xl': '2.313rem',
                      }}
                      fontWeight={'400'}
                      pt={{
                        base: '2.915vw',
                        md: '1.198vw',
                        '3xl': '1.4375rem',
                      }}
                    >
                      {details.answer}
                    </AccordionPanel>
                  </>
                )}
              </AccordionItem>
            );
          })}
        </Accordion>
      </Box>
    </Box>
  );
};
