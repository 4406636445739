import { Box, Button, Flex, Link, Text, transition } from '@chakra-ui/react';
import { motion } from 'framer-motion';
import React, { useState } from 'react';
import { BsArrowRight } from 'react-icons/bs';
// import './AnimateButton.css';

const AnimateButton = ({
  text,
  icon,
  px,
  py,
  bg,
  border,
  arrowColor,
  fontColor,
  radius,
}) => {
  const [isHover, setIsHover] = useState(false);
  // const textArr = ['I','n','s','p','e','c','t' ,'N','o','w'];
  const textArr1 = [text];
  const textArr = textArr1.splice('');

  const alphaUpVariant = {
    initial: {
      opacity: 1,

      y: 0,
      transition: {
        duration: 0.8,
        type: 'tween',
        ease: [0.44, 0, 0, 1],
      },
    },
    animate: {
      opacity: 0,
      y: '-10px',

      transition: {
        duration: 0.5,
        type: 'tween',
        ease: [0.44, 0, 0, 1],
      },
    },
  };
  const alphaDownVariant = {
    initial: {
      opacity: 0,
      y: '10px',
      transition: {
        duration: 0.5,
        type: 'tween',
        // ease: [0.25, 1, 0.5, 1],
        ease: [0.44, 0, 0, 1],
      },
    },
    animate: {
      opacity: 1,
      y: 0,
      transition: {
        delay: 0.05,
        duration: 0.5,
        type: 'tween',
        ease: [0.44, 0, 0, 1],
      },
    },
  };
  console.log(textArr);

  return (
    <Link href="/contactus" _hover={{ textDecoration: 'none' }}>
      <Button
        w={{ base: 'min(32vw,200px)', md: 'min(11.2vw,215px)' }}
        zIndex={'100'}
        as={motion.button}
        onMouseEnter={() => setIsHover(true)}
        onMouseLeave={() => setIsHover(false)}
        _hover={{ bg: '#354A80', color: 'white' }}
        height={{ base: '10.74vw', md: '3.125vw', '3xl': '3.75rem' }}
        lineHeight={'17px'}
        bgColor={'#354A80'}
        fontSize={{ base: '3.625vw', md: '0.99vw', '3xl': '0.94rem' }}
        fontWeight={'400'}
        fontFamily="Source Sans Pro"
        border={'1px solid #354A80'}
        py={{ base: '4.065vw', md: '1.36vw', '3xl': '1.625rem' }}
        px={{ base: '4.44vw', md: '1.05vw', '3xl': '1.25rem' }}
        display="flex"
        color={'#F2F9FB'}
        gap="0"
      >
        {console.log(isHover)}

        {textArr.map((item, index) => {
          console.log(item);
          return (
            <Flex key={index} bg={''} fontFamily="sans-serif">
              <Text
                as={motion.p}
                key={index}
                variants={alphaUpVariant}
                animate={isHover ? 'animate' : 'initial'}
                fontFamily="Source Sans Pro"
                color="white"
                border
              >
                {item}
              </Text>
              <Text
                fontFamily="Source Sans Pro"
                as={motion.p}
                key={index}
                variants={alphaDownVariant}
                // initial="initial"
                animate={isHover ? 'animate' : 'initial'}
                position="absolute"
              >
                {item}
              </Text>
            </Flex>
          );
        })}
        <Box
          as={motion.p}
          animate={
            isHover
              ? {
                  x: '20px',
                  opacity: [1, 0],
                  transition: {
                    duration: 0.5,
                    ease: [0.25, 1, 0.5, 1],
                    type: 'tween',
                    delay: 0.1,
                  },
                }
              : {
                  x: '10px',
                  opacity: 1,
                  transition: {
                    ease: [0.25, 1, 0.5, 1],
                  },
                }
          }
          pl={{ md: '0.15vw', '3xl': '2.88px' }}
        >
          <BsArrowRight
            color={'white'}
            size={
              window.innerWidth < 768
                ? '5vw'
                : '0.94vw' && window.innerWidth > 1920
                ? '1.25rem'
                : '1.1vw'
            }
          />
        </Box>
        <Box
          as={motion.p}
          display={{ md: 'block', base: 'none' }}
          // position="absolute"
          animate={
            isHover
              ? {
                  opacity: [0, 1],
                  delay: 0.5,
                  right: '10px',
                  transition: {
                    duration: 0.5,
                    delay: 0.1,
                    ease: [0.25, 1, 0.5, 1],
                    type: 'tween',
                  },
                }
              : {
                  right: '20px',
                  delay: 0.3,
                  opacity: 0,
                  transition: {
                    duration: 0.5,
                    ease: [0.25, 1, 0.5, 1],
                    type: 'tween',
                  },
                }
          }
        >
          <BsArrowRight
            color={arrowColor}
            size={
              window.innerWidth < 768
                ? '4.6875vw'
                : '0.94vw' && window.innerWidth > 1920
                ? '1.25rem'
                : '0.94vw'
            }
          />
        </Box>
      </Button>
    </Link>
  );
};

export default AnimateButton;
