import { Button, Link } from '@chakra-ui/react';

import React from 'react';

export const RightButton = ({
  gap,
  href,
  text,
  icon,
  bg,
  border,
  fontColor,
  radius,
}) => {
  return (
    <>
      <Link href={href} _hover={{ textDecoration: 'none' }}>
        <Button
          height={{ base: '10.74vw', md: '3.125vw', '3xl': '3.75rem' }}
          py={{ base: '4.065vw', md: '1.36vw', '3xl': '1.625rem' }}
          px={{ base: '3.44vw', md: '1.05vw', '3xl': '1.25rem' }}
          color={fontColor}
          bgColor={bg}
          fontSize={{ base: '3.625vw', md: '0.79vw', '3xl': '0.94rem' }}
          borderRadius={radius}
          border={'1.5px solid'}
          fontWeight={'500'}
          borderColor={border}
          gap={gap}
          rightIcon={icon}
          fontFamily='Source Sans Pro'
        >
          {text}
        </Button>
      </Link>
    </>
  );
};
