import { Box, Flex, Image, Text, Center } from '@chakra-ui/react';
import React from 'react';
import { BsArrowRight } from 'react-icons/bs';
import AnimateButton from '../re-components/AnimateButton';
import { RightButton } from '../re-components/rightButton';

function OurStory() {
  return (
    <Center w={'full'}>
      <Box w={'full'} maxW={'1920px'} bgGradient={''}>
        <Flex flexDir={{ md: 'row', base: 'column-reverse' }}>
          <Image
            src="/abouteciws/ourstory.png"
            objectFit={'cover'}
            width={{ '3xl': '960px', md: '50%', base: '100%' }}
            objectFit={'cover'}
          ></Image>

          <Flex
            width={{ md: '50%', '3xl': '100%', base: '100%' }}
            // height={{
            //   '3xl': '1018px',
            //   // lg: '50vw',
            // }}
            flexDir={'column'}
            paddingTop={{ md: '4vw', '3xl': '70px', base: '54px', sm: '8vw' }}
            paddingBottom={{
              md: '2vw',
              '3xl': '40px',
              base: '66px',
              sm: '8vw',
            }}
            paddingLeft={{
              md: '3.5vw',
              '3xl': '60px',
              base: '20px',
              sm: '4vw',
            }}
            paddingRight={{
              md: '5.5vw',
              '3xl': '100px',
              base: '19px',
              sm: '3.5vw',
            }}
            gap={{
              md: '1.2rem',
              lg: '1.5rem',
              xl: '2rem',
              base: '28px',
              sm: '5vw',
            }}
          >
            <Text
              color={'#000938'}
              fontWeight={'600'}
              fontFamily={'Montserrat'}
              fontSize={{
                md: '2vw',
                '3xl': '40px',
                base: '22px',
                sm: 'min(6vw,35px)',
              }}
              lineHeight={{ md: '3vw', '3xl': '52px', base: '29px', sm: '' }}
            >
              Our Story
            </Text>
            <Box
              display={'flex'}
              flexDir={'column'}
              gap={{ md: '0px', base: '13.5px', sm: '3vw' }}
            >
              <Text
                color={'#000938'}
                fontWeight={'600'}
                fontFamily={'Montserrat'}
                fontSize={{
                  md: '1.3vw',
                  '3xl': '24px',
                  base: '13.5px',
                  sm: 'min(4vw,25px)',
                }}
                lineHeight={{
                  md: '2vw',
                  '3xl': '38px',
                  base: '22px',
                  sm: '25px',
                }}
                width={{ base: '281px', sm: '100%', md: '100%' }}
              >
                Reliable Solutions For Your Industrial Repairs
              </Text>
              <Text
                color={'#464E77'}
                fontFamily={'Montserrat'}
                fontWeight={'500'}
                fontSize={{
                  md: '1.045vw',
                  '3xl': '20px',
                  base: '11.25px',
                  sm: 'min(3vw,18px)',
                }}
                lineHeight={{
                  md: '2vw',
                  '3xl': '35px',
                  base: '22px',
                  sm: 'min(6.8vw,35px)',
                }}
                width={{
                  md: '40.95vw',
                  '3xl': '49.125rem',
                  base: '281px',
                  sm: '100%',
                }}
                align={'justify'}
                letterSpacing={'0.01em'}
              >
                <br />
                Welcome to Expert Creativity Workshop for Industrial Services,
                the undisputed leader in industrial maintenance and repairs in
                Saudi Arabia. With a decade of unrivaled industry experience, we
                have solidified our position as the preferred choice for
                companies seeking unparalleled technical expertise, superior
                service quality, and unwavering customer satisfaction. Our
                workshop offers an extensive range of services dedicated to
                ensuring the seamless operation of your industrial equipment.
                Our highly skilled technicians specialize in the maintenance and
                repair of electric AC/DC motors, power generators, chiller
                compressors, industrial air compressors, and pumps. Whether it's
                a routine inspection, troubleshooting, or intricate repairs, our
                team possesses the knowledge and experience to handle every
                challenge.
                <br />
                Our commitment to excellence extends to continuous training and
                development, keeping our team up-to-date with the latest
                industry standards and best practices. At Expert Creativity
                Workshop, we take immense pride in cultivating strong
                relationships with our clients. We are unwavering in our
                commitment to provide quick turnaround times, cost-effective
                strategies, and exceptional customer service that consistently
                surpasses expectations.
              </Text>
            </Box>
            <Box>
              {/* <RightButton
                icon={<BsArrowRight size={'0.94vw'} />}
                bg={'transparent'}
                border={'base'}
                fontColor={'#000000'}
                text={'Inspect Now'}
              /> */}
              <AnimateButton
                border={'1px solid #354A80'}
                color="white"
                arrowColor="#ffffff"
                bg={'white'}
                text={"Let's Talk"}
              />
            </Box>
          </Flex>
        </Flex>
      </Box>
    </Center>
  );
}
export default OurStory;
