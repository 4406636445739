import React from 'react';
import { Services } from '../../re-components/services';
import { Box, Flex } from '@chakra-ui/react';
import { Helmet } from 'react-helmet';

const Generator = () => {
  return (
    <Box bgColor={'#F4F9FF'}>
      <Helmet>
        <title>Services | ECIWS</title>
      </Helmet>
      {/* <Flex flexDir={'column'}>
        <Services
          menu={'SERVICES'}
          submenu={'Generator Maintenance'}
          paraExtra={
            "When it comes to industrial operations, a reliable power supply is crucial. Regular generator maintenance plays a vital role in ensuring uninterrupted power when you need it the most. Our professional generator maintenance services are designed to keep your equipment in optimal condition, minimizing the risk of unexpected breakdowns and maximizing performance. Our experienced technicians specialize in comprehensive generator maintenance. We perform thorough inspections, checking for fuel and oil leaks, loose connections, and worn-out components. Regular testing of critical functions such as starting mechanisms, voltage output, and load capacity is also conducted. Additionally, we replace air and fuel filters, adjust belts, and perform necessary lubrication to keep your generator running smoothly. By investing in routine maintenance, you can extend the lifespan of your generator and reduce the likelihood of costly repairs. Our team can work with you to create a customized maintenance schedule tailored to your specific needs. We also offer emergency repair services, ensuring minimal downtime in case of unexpected failures. Don't compromise on the reliability of your power supply. Trust our expertise to keep your generator in optimal condition through regular maintenance. Contact us today to schedule a maintenance visit and experience the peace of mind that comes with a well-maintained generator."
          }
          image1={'/generator/generator.jpg'}
        />
        <Services
          menu={'GENERATOR MAINTENANCE'}
          submenu={'Electrical and Mechanical Troubleshooting'}
          paraExtra={
            'Our Electrical and Mechanical Troubleshooting service within Generator Maintenance is designed to address both electrical and mechanical issues with utmost precision and expertise. Our highly skilled technicians are equipped to diagnose and resolve a wide range of problems that may affect the performance and functionality of your generator. When it comes to electrical troubleshooting, our technicians meticulously analyze the various electrical systems of the generator. This includes inspecting the wiring, control panels, voltage regulators, and other components to identify any faults, malfunctions, or wiring issues. With their extensive knowledge and experience, they can quickly pinpoint the root cause of electrical problems and implement effective solutions to restore proper functioning.'
          }
          paraExtra1={
            "In addition to electrical troubleshooting, our technicians also specialize in mechanical diagnostics. They conduct comprehensive inspections of the generator's mechanical components, such as the engine, fuel system, cooling system, exhaust system, and more. By carefully examining these vital parts, they can identify any mechanical issues or abnormalities that may hinder the generator's performance. With their expertise, they provide accurate diagnoses and offer efficient solutions to resolve mechanical problems promptly. We understand the critical role generators play in ensuring uninterrupted power supply. That's why our dedicated team utilizes state-of-the-art diagnostic tools and techniques to deliver reliable troubleshooting services. We are committed to resolving electrical and mechanical issues effectively, ensuring your generator operates optimally and delivers the power you need, when you need it. Trust our experienced technicians to deliver exceptional electrical and mechanical troubleshooting services for your generator, guaranteeing reliable performance and peace of mind."
          }
          image1={'/generatorService/electrical.jpg'}
        />
        <Services
          menu={'GENERATOR MAINTENANCE'}
          submenu={'Voltage Adjustment'}
          paraExtra={
            'In Voltage Adjustment service, our skilled technicians specialize in ensuring optimal voltage levels for your electrical systems. Voltage plays a critical role in the performance and longevity of electrical equipment, and incorrect voltage settings can lead to malfunctions, inefficiencies, and even damage. To address this, our team conducts a thorough assessment of your electrical systems, measuring and analyzing voltage fluctuations and discrepancies. Utilizing advanced testing equipment, our technicians determine the existing voltage levels and compare them to the recommended range. Based on their findings, our experts develop a customized plan to adjust and calibrate the voltage settings to the ideal range for your specific equipment and requirements. This may involve making precise adjustments to voltage regulators, transformers, or other relevant components to achieve stable and consistent voltage output. By ensuring that voltage levels are within the recommended parameters, we optimize the performance and reliability of your electrical systems.'
          }
          paraExtra1={
            'At Expert Creativity Workshop, we prioritize the safety and efficiency of your electrical infrastructure. Our voltage adjustment service aims to safeguard your equipment, prevent potential damage, and enhance overall system reliability. You can trust our team to deliver professional and reliable voltage adjustment solutions tailored to your unique needs. Experience the benefits of properly adjusted voltage levels with our dedicated team of experts. Contact us today to learn more about our voltage adjustment service and how we can optimize your electrical systems for peak performance.'
          }
          image1={'/generatorService/voltage.jpg'}
        />
        <Services
          menu={'GENERATOR MAINTENANCE'}
          submenu={'Alternator and Exciter Rewinding'}
          paraExtra={
            "In our specialized Alternator and Excitor Rewinding service, we specialize in rewinding alternators and exciters to restore their optimal functionality and performance. Our skilled technicians possess extensive knowledge and experience in handling these intricate electrical components. Over time, alternators and exciters may experience wear and tear, resulting in decreased efficiency or complete failure. Rewinding these components involves replacing the worn-out or damaged winding coils, which are crucial for generating the electrical output. Our technicians begin by carefully disassembling the alternator or exciter, inspecting the winding coils for any signs of damage, such as insulation breakdown or coil failure. Using precision techniques and high-quality materials, they expertly rewind the coils, ensuring precise and accurate winding configurations. Once the rewinding process is complete, our technicians meticulously reassemble the alternator or exciter, paying close attention to proper alignment and electrical connections. They conduct thorough tests to verify the rewound components' performance, ensuring they meet or exceed industry standards."
          }
          paraExtra1={
            'By choosing our Alternator and Excitor Rewinding service, you can expect restored functionality and improved reliability for your electrical systems. Our focus on precision rewinding techniques and the use of top-quality materials ensures that your alternators and exciters operate efficiently and withstand the demands of your applications. Experience the benefits of our expertise and trust our dedicated team to deliver exceptional alternator and excitor rewinding services. Contact us today to discuss your specific needs and let us provide tailored solutions to enhance the performance and longevity of your electrical systems.'
          }
          image1={'/generatorService/alternator.jpg'}
        />
        <Services
          menu={'GENERATOR MAINTENANCE'}
          submenu={
            'Supply and installation of all Mechanical and Electrical Spares like AVR, Diodes, control panel, fuel Pump'
          }
          paraExtra={
            'Our comprehensive service of Supply and Installation of all Mechanical and Electrical Spares encompasses a wide range of components such as AVR, diodes, control panels, and fuel pumps. We are dedicated to providing high-quality spares and ensuring their seamless integration into your systems. Our team excels in sourcing top-notch mechanical and electrical spares from reputable manufacturers. We understand the importance of using reliable and genuine parts to maintain the performance and longevity of your equipment. Whether you need an Automatic Voltage Regulator (AVR) to stabilize electrical output, diodes for efficient current control, control panels for system management, or fuel pumps for optimal fuel delivery, we have you covered. Once we have procured the required spares, our skilled technicians handle the installation process with precision and expertise. They are well-versed in working with various equipment brands, ensuring seamless integration and compatibility. Our technicians meticulously follow industry standards and best practices to guarantee the proper functioning of the installed spares.'
          }
          paraExtra1={
            'We prioritize customer satisfaction and strive for excellence in all our services. By opting for our Supply and Installation of Mechanical and Electrical Spares, you can be confident in receiving genuine parts, reliable installation, and enhanced performance of your systems. We aim to minimize downtime and optimize the efficiency of your machinery. Choose our trusted services for the supply and installation of mechanical and electrical spares. Contact us today to discuss your requirements and benefit from our extensive expertise and commitment to delivering quality solutions.'
          }
          image1={'/generatorService/supply.jpg'}
        />
      </Flex> */}
      <Flex flexDir={'column'}>
        <Services
          menu={'SERVICES'}
          submenu={'Generator Maintenance'}
          paraExtra={
            "When it comes to industrial operations, a reliable power supply is crucial. Regular generator maintenance plays a vital role in ensuring uninterrupted power when you need it the most. Our professional generator maintenance services are designed to keep your equipment in optimal condition, minimizing the risk of unexpected breakdowns and maximizing performance. Our experienced technicians specialize in comprehensive generator maintenance. We perform thorough inspections, checking for fuel and oil leaks, loose connections, and worn-out components. Regular testing of critical functions such as starting mechanisms, voltage output, and load capacity is also conducted. Additionally, we replace air and fuel filters, adjust belts, and perform necessary lubrication to keep your generator running smoothly. By investing in routine maintenance, you can extend the lifespan of your generator and reduce the likelihood of costly repairs. Our team can work with you to create a customized maintenance schedule tailored to your specific needs. We also offer emergency repair services, ensuring minimal downtime in case of unexpected failures. Don't compromise on the reliability of your power supply. Trust our expertise to keep your generator in optimal condition through regular maintenance. Contact us today to schedule a maintenance visit and experience the peace of mind that comes with a well-maintained generator."
          }
          image1={'/generatorService/generator.png'}
        />
        <Services
          menu={'GENERATOR MAINTENANCE'}
          submenu={'Electrical and Mechanical Troubleshooting'}
          paraExtra={
            'Our Electrical and Mechanical Troubleshooting service within Generator Maintenance is designed to address both electrical and mechanical issues with utmost precision and expertise. Our highly skilled technicians are equipped to diagnose and resolve a wide range of problems that may affect the performance and functionality of your generator. When it comes to electrical troubleshooting, our technicians meticulously analyze the various electrical systems of the generator. This includes inspecting the wiring, control panels, voltage regulators, and other components to identify any faults, malfunctions, or wiring issues. With their extensive knowledge and experience, they can quickly pinpoint the root cause of electrical problems and implement effective solutions to restore proper functioning.'
          }
          paraExtra1={
            "In addition to electrical troubleshooting, our technicians also specialize in mechanical diagnostics. They conduct comprehensive inspections of the generator's mechanical components, such as the engine, fuel system, cooling system, exhaust system, and more. By carefully examining these vital parts, they can identify any mechanical issues or abnormalities that may hinder the generator's performance. With their expertise, they provide accurate diagnoses and offer efficient solutions to resolve mechanical problems promptly. We understand the critical role generators play in ensuring uninterrupted power supply. That's why our dedicated team utilizes state-of-the-art diagnostic tools and techniques to deliver reliable troubleshooting services. We are committed to resolving electrical and mechanical issues effectively, ensuring your generator operates optimally and delivers the power you need, when you need it. Trust our experienced technicians to deliver exceptional electrical and mechanical troubleshooting services for your generator, guaranteeing reliable performance and peace of mind."
          }
          image1={'/generatorService/electrical.png'}
        />
        <Services
          menu={'GENERATOR MAINTENANCE'}
          submenu={'Voltage Adjustment'}
          paraExtra={
            'In Voltage Adjustment service, our skilled technicians specialize in ensuring optimal voltage levels for your electrical systems. Voltage plays a critical role in the performance and longevity of electrical equipment, and incorrect voltage settings can lead to malfunctions, inefficiencies, and even damage. To address this, our team conducts a thorough assessment of your electrical systems, measuring and analyzing voltage fluctuations and discrepancies. Utilizing advanced testing equipment, our technicians determine the existing voltage levels and compare them to the recommended range. Based on their findings, our experts develop a customized plan to adjust and calibrate the voltage settings to the ideal range for your specific equipment and requirements. This may involve making precise adjustments to voltage regulators, transformers, or other relevant components to achieve stable and consistent voltage output. By ensuring that voltage levels are within the recommended parameters, we optimize the performance and reliability of your electrical systems.'
          }
          paraExtra1={
            'At Expert Creativity Workshop, we prioritize the safety and efficiency of your electrical infrastructure. Our voltage adjustment service aims to safeguard your equipment, prevent potential damage, and enhance overall system reliability. You can trust our team to deliver professional and reliable voltage adjustment solutions tailored to your unique needs. Experience the benefits of properly adjusted voltage levels with our dedicated team of experts. Contact us today to learn more about our voltage adjustment service and how we can optimize your electrical systems for peak performance.'
          }
          image1={'/generatorService/voltage.png'}
        />
        <Services
          menu={'GENERATOR MAINTENANCE'}
          submenu={'Alternator and Exciter Rewinding'}
          paraExtra={
            "In our specialized Alternator and Excitor Rewinding service, we specialize in rewinding alternators and exciters to restore their optimal functionality and performance. Our skilled technicians possess extensive knowledge and experience in handling these intricate electrical components. Over time, alternators and exciters may experience wear and tear, resulting in decreased efficiency or complete failure. Rewinding these components involves replacing the worn-out or damaged winding coils, which are crucial for generating the electrical output. Our technicians begin by carefully disassembling the alternator or exciter, inspecting the winding coils for any signs of damage, such as insulation breakdown or coil failure. Using precision techniques and high-quality materials, they expertly rewind the coils, ensuring precise and accurate winding configurations. Once the rewinding process is complete, our technicians meticulously reassemble the alternator or exciter, paying close attention to proper alignment and electrical connections. They conduct thorough tests to verify the rewound components' performance, ensuring they meet or exceed industry standards."
          }
          paraExtra1={
            'By choosing our Alternator and Excitor Rewinding service, you can expect restored functionality and improved reliability for your electrical systems. Our focus on precision rewinding techniques and the use of top-quality materials ensures that your alternators and exciters operate efficiently and withstand the demands of your applications. Experience the benefits of our expertise and trust our dedicated team to deliver exceptional alternator and excitor rewinding services. Contact us today to discuss your specific needs and let us provide tailored solutions to enhance the performance and longevity of your electrical systems.'
          }
          image1={'/generatorService/alternator.png'}
        />
        <Services
          menu={'GENERATOR MAINTENANCE'}
          submenu={
            'Supply and installation of all Mechanical and Electrical Spares like AVR, Diodes, control panel, fuel Pump'
          }
          paraExtra={
            'Our comprehensive service of Supply and Installation of all Mechanical and Electrical Spares encompasses a wide range of components such as AVR, diodes, control panels, and fuel pumps. We are dedicated to providing high-quality spares and ensuring their seamless integration into your systems. Our team excels in sourcing top-notch mechanical and electrical spares from reputable manufacturers. We understand the importance of using reliable and genuine parts to maintain the performance and longevity of your equipment. Whether you need an Automatic Voltage Regulator (AVR) to stabilize electrical output, diodes for efficient current control, control panels for system management, or fuel pumps for optimal fuel delivery, we have you covered. Once we have procured the required spares, our skilled technicians handle the installation process with precision and expertise. They are well-versed in working with various equipment brands, ensuring seamless integration and compatibility. Our technicians meticulously follow industry standards and best practices to guarantee the proper functioning of the installed spares.'
          }
          paraExtra1={
            'We prioritize customer satisfaction and strive for excellence in all our services. By opting for our Supply and Installation of Mechanical and Electrical Spares, you can be confident in receiving genuine parts, reliable installation, and enhanced performance of your systems. We aim to minimize downtime and optimize the efficiency of your machinery. Choose our trusted services for the supply and installation of mechanical and electrical spares. Contact us today to discuss your requirements and benefit from our extensive expertise and commitment to delivering quality solutions.'
          }
          image1={'/generatorService/supply.png'}
        />
      </Flex>
    </Box>
  );
};

export default Generator;
