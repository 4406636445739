import { extendTheme } from '@chakra-ui/react';

const breakpoints = {
  // for mobile and below
  sm: '350px',
  // Custom
  sm2: '550px',
  // here starts ipad
  md: '768px',
  // ipad+ size 11 inch plus
  lg: '960px',
  // desktops
  xl: '1200px',
  // desktops+
  '2xl': '1536px',
  // desktops++
  '3xl': '2000px',
};

const colors = {
  base: '#0084A3',
};

const theme = extendTheme({
  breakpoints,
  colors,
});

export default theme;
