import { Box, Button, Center, Divider, Image, Text } from '@chakra-ui/react';
import React from 'react';
import '../styles/index.css';

export const MachinesInspected = () => {
  const achievements = [
    {
      id: 1,
      number: '10,000+',
      text: 'Machines Inspected',
      display: 'inline-block',
    },
    {
      id: 2,
      number: '8000+',
      text: 'Certified',
      display: 'none',
    },
  ];

  return (
    <Center w={'full'} bg={'#000938'}>
      <Box
        w={'full'}
        bg={'#000938'}
        maxW={'1920px'}
        display={'flex'}
        flexDir={{ base: 'column', md: 'row' }}
        py={{ base: '14.375vw', md: '13.25vw', '3xl': '15.9375rem' }}
        px={{ base: '6.875vw', md: '5.5vw', '3xl': '6.625rem' }}
        pb={{ base: '26.40vw', md: '13.25vw', '3xl': '15.9375rem' }}
        gap={{ base: '26.25vw', md: '0' }}
        alignItems={'center'}
        justifyContent={'space-between'}
      >
        <Box
          display={'flex'}
          gap={{ base: '5.3125vw', md: '2.9vw', '3xl': '3.5rem' }}
          flexDirection={'column'}
        >
          <Text
            maxW={'27ch'}
            fontFamily={'Montserrat'}
            fontWeight={'400'}
            fontSize={{ base: '5.77vw', md: '2.4vw', '3xl': '2.875rem' }}
            color={'white'}
            lineHeight={{ base: '8.06vw', md: '3.335vw', '3xl': '4rem' }}
          >
            Trusted by fast-moving Companies & Industries worldwide
          </Text>
          <Box
            display={'flex'}
            gap={{ base: '9.375vw', md: '2.575vw', '3xl': '3.0625rem' }}
            alignItems={'center'}
          >
            {achievements.map(data => {
              return (
                <>
                  <Box
                    key={data.id}
                    display={'flex'}
                    flexDirection={'column'}
                    gap={{ base: '1.3875vw', md: '0.5vw', '3xl': '0.625rem' }}
                  >
                    <Text
                      fontFamily="Source Sans Pro"
                      fontWeight={'600'}
                      fontSize={{
                        base: '6.328vw',
                        md: '2.4vw',
                        '3xl': '2.875rem',
                      }}
                      lineHeight={{
                        base: '8.8375vw',
                        md: '3.335vw',
                        '3xl': '4rem',
                      }}
                      color={'#ffffff'}
                    >
                      {data.number}
                    </Text>
                    <Text
                      fontFamily="Source Sans Pro"
                      fontWeight={'400'}
                      fontSize={{
                        base: '3.578vw',
                        md: '1.355vw',
                        '3xl': '1.625rem',
                      }}
                      lineHeight={{
                        base: '4.2vw',
                        md: '1.65vw',
                        '3xl': '1.9125rem',
                      }}
                      color={'#ffffff'}
                    >
                      {data.text}
                    </Text>
                  </Box>
                  <Divider
                    display={data.display}
                    h={{ base: '10vw', md: '4vw', '3xl': '4.875rem' }}
                    orientation="vertical"
                  />
                </>
              );
            })}
          </Box>
        </Box>
        <Box
          display={'flex'}
          flexDirection={'column'}
          color={'white'}
          gap={{ base: '11.409vw', md: '6vw', '3xl': '5rem' }}
        >
          <Box
            display={'flex'}
            gap={{ base: '14.0625vw', md: '5.1vw', '3xl': '6.125rem' }}
          >
            <Image
              // h={{ base: '5.706vw', md: '4vw', '3xl': '91px' }}
              w={{ base: 'min(15vw,75px)', md: 'min(8vw,164px)' }}
              // h={{ base: '5.706vw', md: '2.9vw', '3xl': '91px' }}
              src="./eciws/cat.svg"
            />
            <Image
              w={{ base: 'min(15vw,75px)', md: 'min(8vw,164px)' }}
              // h={{ base: '5.706vw', md: '2.5vw', '3xl': '91px' }}
              src="./eciws/atlas.svg"
            />
            <Image
              // h={{ base: '5.706vw', md: '2.5vw', '3xl': '91px' }}
              w={{ base: 'min(15vw,75px)', md: 'min(8vw,164px)' }}
              src="./eciws/aggreko.svg"
            />
          </Box>
          <Box
            display={'flex'}
            gap={{ base: '14.0625vw', md: '5.1vw', '3xl': '6.125rem' }}
          >
            <Image
              // h={{ base: '5.706vw', md: '2.15vw', '3xl': '2.5625rem' }}
              w={{ base: 'min(15vw,75px)', md: 'min(8vw,164px)' }}
              src="./eciws/sullair.svg"
            />
            <Image
              // h={{ base: '5.706vw', md: '2.15vw', '3xl': '2.5625rem' }}
              w={{ base: 'min(15vw,75px)', md: 'min(8vw,164px)' }}
              src="./eciws/ksb.svg"
            />
            <Image
              // h={{ base: '5.706vw', md: '2.15vw', '3xl': '2.5625rem' }}
              w={{ base: 'min(15vw,75px)', md: 'min(8vw,164px)' }}
              src="./eciws/busch.svg"
            />
          </Box>
        </Box>
      </Box>
    </Center>
  );
};
