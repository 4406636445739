import { Box, Center, Link } from '@chakra-ui/react';
import React from 'react';

const Thankyou = () => {
  return (
    <Center
      width={'100%'}
      height={'80vh'}
      display={'flex'}
      justifyContent={'center'}
      alignItems={'center'}
      flexDir={'column'}
      gap={'10px'}
    >
      <Box
        height={'80vh'}
        width={{ lg: '100%', base: '90%' }}
        display={'flex'}
        justifyContent={'center'}
        alignItems={'center'}
        flexDir={'column'}
        gap={'10px'}
      >
        <Box
          color={'#000938'}
          fontSize={{ lg: '40px', base: '20px' }}
          fontWeight={600}
        >
          Thankyou!
        </Box>
        <Box
          color={'#464E77'}
          fontSize={{ lg: '25px', base: '16px' }}
          fontWeight={500}
          textAlign={'center'}
        >
          Form Submitted: Expect Swift Response as We Propel Towards
          Achievement.
        </Box>
        <Link
          color={'#464E77'}
          fontSize={{ lg: '22px', base: '14px' }}
          fontWeight={600}
          href='/'
        >
          <u> Go Back</u>
        </Link>
      </Box>
    </Center>
  );
};

export default Thankyou;
