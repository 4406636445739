import React from 'react';
import DesktopOfferComponent from './DesktopOfferComponent';
import {
  Box,
  Center,
  Flex,
  Text,
  Image,
  Grid,
  GridItem,
} from '@chakra-ui/react';
export default function WhatWeOfferDesktop() {
  return (
    <Center w={'full'}>
      <Box
        w={'full'}
        maxW={'1920px'}
        padding={{ md: '5.729vw', '3xl': '110px' }}
        display={{ md: 'flex', base: 'none' }}
        gap={'2rem'}
        fontFamily={'Montserrat'}
        flexDir={'column'}
        bg={'#F4F9FF'}
      >
        <Flex flexDir={'column'} gap={'1.5vw'}>
          <Text
            fontWeight={'600'}
            fontSize={{ '3xl': '60px', md: '3vw' }}
            lineHeight={'52px'}
            color={'#000938'}
          >
            What we offer
          </Text>
          <Text
            fontWeight={'600'}
            fontSize={{ '3xl': '24px', md: '1.2vw' }}
            lineHeight={'17px'}
            letterSpacing={'3%'}
            color={'#000938'}
          >
            A Total Solution To All Your Inspection Needs
          </Text>
        </Flex>

        <Grid
          maxw={'1706px'}
          gridTemplateAreas={{
            md: '"one one two five" "three four four five"',
          }}
          gap={{ md: '1.822vw', '3xl': '35px' }}
        >
          <GridItem gridArea={'one'}>
            <DesktopOfferComponent
              topradius={'25px'}
              border="1px solid #DEECEF"
              h={{ '3xl': '446px', md: '23.33vw' }}
              // borderTopLeftRadius={'25px'}
              w={{ md: '33.645vw', '3xl': '646px' }}
              pb={{ md: '6.718vw', '3xl': '129px' }}
              pr={{ md: '1.822vw', '3xl': '35px' }}
              tittle={'Our Objectives'}
              hello={
                'Our primary objective to our client is ensuring prompt job execution'
              }
              img={'/whatweoffer/Subtract.png'}
            />
          </GridItem>
          <GridItem gridArea={'two'}>
            <DesktopOfferComponent
              w={{ md: '25.93vw', '3xl': '498px' }}
              // h={'446px'}
              h={{ '3xl': '446px', md: '23.33vw' }}
              pb={{ md: '4.4vw', lg: '2.8vw', '3xl': '125px' }}
              pr={{ md: '4vw', '3xl': '70px' }}
              tittle={'Our Standards'}
              img={'/whatweoffer/Union.png'}
              hello={
                'High quality standards in accordance to PDO and and Non-PDO specifications, BS, EN, ASME, ISO'
              }
            />
          </GridItem>

          <GridItem
            gridArea={'five'}
            borderTopRightRadius={'25px'}
            borderBottomRightRadius={'25px'}
          >
            <Box
              border={'1px solid '}
              borderRadius={'25px'}
              borderColor={'#C9DDF5'}
              w={{ md: '22vw', '3xl': '491px' }}
              bg={'white'}
              display={'flex'}
              flexDir={'column'}
              gap={{ '3xl': '199px', md: '10.364vw' }}
              //   gap={{ md: '1.0416vw', '3xl': '1.25rem' }}
              pl={{ '3xl': '36px', md: '1.875vw' }}
              pt={{ '3xl': '35px', md: '1.822vw' }}
              pr={{ '3xl': '38px', md: '1.979vw' }}
              pb={{ '3xl': '101px', md: '5.26vw' }}
            >
              <Box
                display={'flex'}
                flexDir={'column'}
                gap={{ md: '1.0416vw', '3xl': '1.25rem' }}
              >
                <Box
                  bgColor={'#CFD0E8'}
                  width={{ '3xl': '100px', md: '5.208vw' }}
                  height={{ '3xl': '100px', md: '5.208vw' }}
                  alignItems={'center'}
                  display={'flex'}
                  justifyContent={'center'}
                  borderRadius={'50%'}
                >
                  <Image
                    src={'/whatweoffer/new.png'}
                    width={{ '3xl': '56px', md: '2.916vw' }}
                  />
                </Box>
                <Text
                  fontWeight={'600'}
                  fontSize={{ '3xl': '44px', md: '2.291vw' }}
                  lineHeight={{ '3xl': '52px', md: '2.70vw' }}
                  color={'#000938'}
                >
                  Our Promise
                </Text>
                <Text
                  pt={{ md: '0.834vw', '3xl': '1rem' }}
                  fontWeight={'500'}
                  fontSize={{ '3xl': '20px', lg: '1vw', md: '1.041vw' }}
                  lineHeight={{ '3xl': '37px', md: '1.927vw' }}
                  color={'#464E77'}
                  letterSpacing={'2%'}
                  Width={'35ch'}
                >
                  Guarantee high level of quality for every service execution.
                </Text>
              </Box>
              <Box
                display={'flex'}
                flexDir={'column'}
                gap={{ md: '1.0416vw', '3xl': '1.25rem' }}
              >
                <Box
                  bgColor={'#CFD0E8'}
                  width={{ '3xl': '100px', md: '5.208vw' }}
                  height={{ '3xl': '100px', md: '5.208vw' }}
                  alignItems={'center'}
                  display={'flex'}
                  justifyContent={'center'}
                  borderRadius={'50%'}
                >
                  <Image
                    src={'/whatweoffer/motto.png'}
                    width={{ '3xl': '56px', md: '2.916vw' }}
                  />
                </Box>
                <Text
                  fontWeight={'600'}
                  fontSize={{ '3xl': '44px', md: '2.291vw' }}
                  lineHeight={{ '3xl': '52px', md: '2.70vw' }}
                  color={'#000938'}
                >
                  Our Motto
                </Text>
                <Text
                  pt={{ md: '0.834vw', '3xl': '1rem' }}
                  fontWeight={'500'}
                  // fontSize={{ "2xl": "20px", xl: "16px", lg: "12px" }}
                  fontSize={{ '3xl': '20px', lg: '1vw', md: '1.041vw' }}
                  lineHeight={{ '3xl': '37px', md: '1.927vw' }}
                  color={'#464E77'}
                  letterSpacing={'2%'}
                  Width={'35ch'}
                >
                  Utter commitment in all and any work we undertake
                </Text>
              </Box>
            </Box>
          </GridItem>

          <GridItem
            gridArea={'three'}
            display={'flex'}
            borderBottomLeftRadius={'25px'}
          >
            <DesktopOfferComponent
              bottomradius={'25px'}
              w={{ md: '22.70vw', '3xl': '436px' }}
              h={{ md: '21.30vw', '3xl': '409px' }}
              pb={{ md: '2.76vw', '3xl': '53px' }}
              pr={{ md: '3.80vw', '3xl': '73px' }}
              img={'/whatweoffer/dollar.png'}
              tittle={'Our Pricing'}
              hello={
                'Affordable and uniform prices across all our services to all our clients'
              }
            />
          </GridItem>
          <GridItem gridArea={'four'}>
            <DesktopOfferComponent
              w={{ md: '36.92vw', '3xl': '709px' }}
              h={{ md: '21.30vw', '3xl': '409px' }}
              pb={{ md: '2.01vw', '3xl': '90px' }}
              pr={{ md: '4.63vw', '3xl': '89px' }}
              img={'/whatweoffer/like.png'}
              tittle={'Our Approvals & Accreditations'}
              hello={
                'Approvalls and accreditation from LEEA, PDO, JSRS, OPAL, SGS'
              }
            />
          </GridItem>
        </Grid>

        <Box>
          <Center>
            <Text
              fontWeight={'500'}
              fontSize={{ '3xl': '24px', md: '1.25vw' }}
              lineHeight={'17px'}
              letterSpacing={'3%'}
            >
              So what are you waiting for?{' '}
            </Text>
            <Text
              fontWeight={'500'}
              fontSize={{ '3xl': '24px', md: '1.25vw' }}
              lineHeight={'17px'}
              pl={{ md: '0.4vw', '3xl': '0.6rem' }}
              letterSpacing={'3%'}
              color={'#27388E'}
              as={'span'}
            >
              {'   '}
              Avail our service for inspection and load test certificate today!
            </Text>
          </Center>
        </Box>
      </Box>
    </Center>
  );
}
