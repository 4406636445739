import { Box, Center, Image, Text } from '@chakra-ui/react';
import React, { useEffect } from 'react';
import { BsArrowUpRight, BsDownload } from 'react-icons/bs';
import ArrowUpBtn from './ArrowUpBtn';
import { RightButton } from './rightButton';

export const Services = ({
  menu,
  submenu,
  title1,
  title2,
  title3,
  titleColor,
  paraExtra,
  paraExtra1,
  hasBullets,
  bulletPoints1,
  bulletPoints2,
  bulletPoints3,
  para1,
  para2,
  para2last,
  para3,
  bulletTitle1,
  bulletTitlePoints1,
  bulletTitle2,
  bulletTitlePoints2,
  bulletTitle3,
  bulletTitlePoints3,
  bulletTitle4,
  bulletTitlePoints4,
  image1,
  image2,
  image3,
  pt1,
  pt2,
  href1,
  href2,
}) => {
  const paddingleft = {
    base: '6.45vw',
    sm: '6.25vw',
    sm2: '5.35vw',
    md: '2vw',
    lg: '1.775vw',
    xl: '1.775vw',
    '2xl': '1.675vw',
    '3xl': '2rem',
  };

  return (
    <Center w={'full'}>
      <Box
        w={'90%'}
        maxW={'1920px'}
        fontFamily={'Source Sans Pro'}
        display={'flex'}
        flexDirection={'column'}
        pt={{ base: '0', md: '3.125vw', '3xl': '3.75rem' }}
        // pl={{ base: '0', md: '5.775vw', '3xl': '6.875rem' }}
        pb={{ base: '0', md: '3.475vw', '3xl': '5.375rem' }}
        alignItems={'start'}
      >
        <Text
          color={'#000938'}
          fontWeight={'600'}
          display={{ base: 'none', md: 'block' }}
          fontFamily={'Montserrat'}
          align={'justify'}
          fontSize={{ md: '1.2vw', '3xl': '1.5rem' }}
          lineHeight={{ md: '1.375vw', '3xl': '5.25rem' }}
          textTransform={'capitalize'}
          // pb={{ md: '0.6045vw', '3xl': '3.125rem' }}
        >
          {menu}
        </Text>
        <Text
          display={{ base: 'none', md: 'block' }}
          color={'#000938'}
          fontWeight={{ md: '600' }}
          fontFamily={'Montserrat'}
          // align={'justify'}s
          fontSize={{ md: '1.9795vw', '3xl': '2.375rem' }}
          lineHeight={{ md: '4.167vw', '3xl': '5rem' }}
          pb={{ md: '0.5625vw', '3xl': '1.875rem' }}
        >
          {submenu}
        </Text>
        <Box
          w={'full'}
          display={'flex'}
          flexDir={{ base: 'column-reverse', md: 'row' }}
          justifyContent={'space-between'}
          alignItems={'start'}
          pb={{ base: '12.5vw', md: '2.917vw', '3xl': '3.5rem' }}
        >
          <Box
            w={{ base: '90%', md: '57.29vw', '3xl': '68.75rem' }}
            alignSelf={{ base: 'center', lg: 'normal' }}
            display={'flex'}
            flexDirection={'column'}
            color={'#000000'}
            fontSize={{ base: '3.5vw', md: '1.041675vw', '3xl': '1.25rem' }}
            fontWeight={'400'}
            lineHeight={{ base: '7.125vw', md: '2.1875vw', '3xl': '2.625rem' }}
            textAlign={'justify'}
          >
            <Text
              color={'#000938'}
              fontWeight={'600'}
              display={{ base: 'block', md: 'none' }}
              // align={'justify'}
              fontSize={{ md: '1.2vw', '3xl': '1.5rem' }}
              lineHeight={{ md: '1.375vw', '3xl': '5.25rem' }}
              textTransform={'capitalize'}
              mt={'2rem'}
              // pb={{ md: '0.6045vw', '3xl': '3.125rem' }}
            >
              {menu}
            </Text>
            <Text
              display={{ base: 'block', md: 'none' }}
              color={'#000938'}
              fontWeight={{ base: '500' }}
              align={'start'}
              fontSize={{ base: '5.625vw', md: '1.9795vw', '3xl': '2.375rem' }}
              lineHeight={{ base: '11.875vw', md: '4.167vw', '3xl': '5rem' }}
              pb={{ md: '1.5625vw', '3xl': '1.875rem' }}
              // py={'7.875vw'}
            >
              {submenu}
            </Text>

            <Text
              fontFamily={'Montserrat'}
              fontWeight={'500'}
              fontSize={{ lg: 'min(0.9vw,1.4rem)', base: 'min(3.7vw,20px)' }}
              color={'#464E77'}
              textAlign={'left'}
              lineHeight={{ base: '1.7rem' }}
            >
              {paraExtra}
            </Text>
            {paraExtra1 && (
              <Text
                fontFamily={'Montserrat'}
                fontWeight={'500'}
                fontSize={{ lg: 'min(0.9vw,1.4rem)', base: 'min(3.7vw,20px)' }}
                color={'#464E77'}
                textAlign={'left'}
                lineHeight={{ base: '1.7rem' }}
              >
                {paraExtra1}
              </Text>
            )}
            <Image mt={'2rem'} display={{ lg: 'none' }} src={image1} />

            <Box display={'flex'} gap={'2rem'} mt={'3rem'}>
              <ArrowUpBtn fontColor="#F2F9FB" bg={'base'} text={"Let's Talk"} />
            </Box>
          </Box>
          <Box w={'30%'}>
            <Image
              display={{ lg: 'block', base: 'none' }}
              w={'full'}
              src={image1}
            />
          </Box>
        </Box>
      </Box>
    </Center>
  );
};
