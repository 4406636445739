import { Box, Text, useFocusEffect } from '@chakra-ui/react';
import React from 'react';
import { RightButton } from '../re-components/rightButton';
import { BsArrowRight } from 'react-icons/bs';
import AnimateButton from '../re-components/AnimateButton';
import { gsap } from 'gsap';
import { useEffect, useRef } from 'react';
import { Power2 } from 'gsap';

export const Hero = () => {
  const videoRef = useRef();
  const title = useRef();
  useEffect(() => {
    gsap.fromTo(
      title.current.children,
      {
        opacity: 0,
        ease: 'power2.easeInOut',
      },
      {
        // x: 0,
        opacity: 1,
        duration: 0.8,
        stagger: 0.15,
        ease: 'power2.easeInOut',
      }
    );
    gsap.fromTo(
      '#subtittle',
      {
        opacity: 0,
        ease: 'power2.easeInOut',
      },
      {
        // x: 0,
        opacity: 1,
        duration: 1,
        stagger: 0.2,
        ease: 'power2.easeInOut',
      }
    );

    // herodescription
    gsap.fromTo(
      '#herodescription',
      {
        opacity: 0,
      },
      {
        opacity: 1,
        delay: 1,
        duration: 3,
      }
    );
  }, []);
  return (
    <Box
      minH={'100vh'}
      w={'full'}
      maxW={'1920px'}
      display={'flex'}
      flexDirection={'column'}
      gap={{ base: '3.5vw', md: '1.25vw', '3xl': '1.5rem' }}
      py={{ base: '65vw', md: '15.8vw', '3xl': '19rem' }}
      px={{ base: '6.25vw', md: '20.35vw', '3xl': '24.5rem' }}
      alignItems={'center'}
      position={'relative'}
      zIndex={'10'}
    >
      <Text
        textAlign={'center'}
        fontSize={{ base: '4.875vw', md: '2.85vw', '3xl': '3.4rem' }}
        fontWeight={'500'}
        fontFamily={'Montserrat'}
        color={'#000'}
        lineHeight={{ base: '5.875vw', md: '3.325vw', '3xl': '4rem' }}
        ref={title}
        zIndex={'100'}
      >
        {`ECIWS - Reliable Solutions For Your`.split(' ').map((item, index) => {
          return (
            <Text as={'span'} id="heading" ml={'8px'} key={index}>
              <Box as="span">{item}</Box>
            </Text>
          );
        })}
        <Text as={'span'} color={'#27388E'}>
          {' '}
          {`Industrial Repairs`.split(' ').map((item, idx) => {
            return (
              <Text as={'span'} ml={'5px'} id="subtittle">
                {item}
              </Text>
            );
          })}
        </Text>
      </Text>
      <Text
        w={{ md: '40vw', '3xl': '48rem' }}
        color={'#464E77'}
        textAlign={'center'}
        fontWeight={'400'}
        fontSize={{ base: '3.025vw', md: '1.3vw', '3xl': '1.25rem' }}
        lineHeight={{ base: '3.35vw', md: '1.55vw', '3xl': '1.875rem' }}
        id="herodescription"
        zIndex={'1'}
      >
        Our team of seasoned experts delivers meticulous testing services that
        adhere to the most stringent industry standards, ensuring exceptional
        quality.
      </Text>
      <Box
        mt={{ base: '2.5vw', md: '1.5625vw', '3xl': '1.875rem' }}
        display={'flex'}
        gap={{ base: '3.35vw', md: '0.8vw', '3xl': '1rem' }}
      >
        {/* <RightButton
          height={{ base: '9.89vw', md: '3vw', '3xl': '3.625rem' }}
          bg={'transparent'}
          border={'base'}
          fontColor={'base'}
          text={'Know more'}
          radius={{ base: '7vw', md: '3vw', '3xl': '2.5rem' }}
        /> */}
        {/* <RightButton
          height={{ base: '9.89vw', md: '3vw', '3xl': '3.625rem' }}
          icon={
            <BsArrowRight
              color="#F2F9FB"
              size={
                window.innerWidth < 768
                  ? '4.6875vw'
                  : '0.94vw' && window.innerWidth > 1920
                  ? '1.25rem'
                  : '0.94vw'
              }
            />
          }
          bg={'base'}
          radius={{ base: '7vw', md: '3vw', '3xl': '2.5rem' }}
          border={'base'}
          fontColor={'#F2F9FB'}
          gap={{ md: '0.7vw', '3xl': '15px' }}
          text={'Inspect Now'}
        /> */}
        <AnimateButton
          // text={'Inspect Now'}
          icon={
            <BsArrowRight
              color="#F2F9FB"
              size={
                window.innerWidth < 768
                  ? '2.0875vw'
                  : '0.94vw' && window.innerWidth > 1920
                  ? '1.25rem'
                  : '0.94vw'
              }
            />
          }
          borderRadius=""
          bg={'base'}
          border={'base'}
          fontColor={'#F2F9FB'}
          text={'Lets Talk'}
          radius={{ base: '7vw', md: '3vw', '3xl': '2.5rem' }}
          fontSize={{
            '3xl': '20px',
            lg: '1.04vw',
            md: '1.2vw',
            base: '3.75vw',
          }}
          // px={{ base: '2.13vw', md: '1vw', lg: '1.6vw   ' }}
          // py={{ base: '2.23vw', md: '1.55vw', lg: '0.52vw' }}
          margin={{ md: '0', base: 'auto' }}
          gap={{ md: '0' }}
        />
      </Box>
      <Box
        position={'absolute'}
        top={'0'}
        width={'100%'}
        h={'full'}
        objectFit={'cover'}
        opacity={'0.3'}
        zIndex={'-100'}
        overflow={'hidden'}
      >
        <video
          ref={videoRef}
          style={{ objectFit: 'cover', height: '170vh', zIndex: '-100' }}
          width={'100%'}
          src="/video/eciwsbg.mp4"
          autoPlay
          loop
          muted
        ></video>
      </Box>
    </Box>
  );
};
