import React from 'react';
import Feedback from '../components/Feedback';
import Inquiry from '../components/Inquiry';
import SupportContent from '../components/SupportContent';
import { Faqs } from '../components/faqs';
import { Box } from '@chakra-ui/react';
import { Helmet } from 'react-helmet';

const Support = () => {
  return (
    <Box bgColor={'#F4F9FF'}>
      <Helmet>
        <title>Support | ECIWS</title>
      </Helmet>
      <SupportContent />
      <Faqs />
      <Feedback />
      <Inquiry />
    </Box>
  );
};

export default Support;
