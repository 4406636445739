import { Box, Center, Text } from '@chakra-ui/react';
import React from 'react';
import Titledescr from './reusable/Titledescr';
// import HeadingPara from './headingpara';

const SupportContent = () => {
  return (
    <Center w={'full'} mb={'4rem'} pt={{ lg: '4rem' }}>
      <Box
        w={'90%'}
        fontFamily={'Montserrat'}
        display={'flex'}
        flexDir={'column'}
        gap={'2.5vw'}
      >
        <Text
          fontWeight={'600'}
          mt={'2rem'}
          mb={{ base: '2rem', lg: '0rem' }}
          fontSize={{ lg: 'min(2.2vw,2.5rem)', base: '1.4rem' }}
        >
          How can we help you?
        </Text>
        <Box display={'flex'} flexDir={'column'} gap={'35px'}>
          <Titledescr
            headline={'Technical Assistance:'}
            description={
              "Our team of experienced technicians is available to provide technical assistance and support for any issues you may encounter. Whether you need help troubleshooting equipment problems, understanding product specifications, or optimizing performance, our experts are here to assist you. Contact our technical support helpline or submit a support ticket, and we'll promptly address your inquiries and provide step-by-step guidance to resolve any technical challenges."
            }
          />
          <Titledescr
            headline={'Spare Parts and Component Sourcing:'}
            description={
              'Finding the right spare parts and components for your industrial equipment can be a daunting task. We simplify the process by offering comprehensive spare parts and component sourcing services. Our knowledgeable team will assist you in identifying and procuring the required parts, ensuring compatibility and quality. With our extensive network of trusted suppliers and manufacturers, we can source genuine parts efficiently, saving you time and effort. Reach out to our support team to access our reliable spare parts and component sourcing service.'
            }
          />
          <Titledescr
            headline={'Maintenance Contracts and Service Agreements:'}
            description={
              'To streamline your maintenance activities and ensure regular servicing of your industrial equipment, we offer customized maintenance contracts and service agreements. These agreements provide you with the convenience of pre-scheduled maintenance visits, priority service, and cost savings. Our team will work closely with you to develop a tailored agreement that suits your specific requirements, helping you stay on top of your maintenance needs while minimizing downtime and maximizing equipment performance. Get in touch with our support team to explore our maintenance contract options and benefit from hassle-free equipment maintenance.'
            }
          />
        </Box>
      </Box>
    </Center>
  );
};

export default SupportContent;
