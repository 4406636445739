export const mobileitems = [
  {
    id: 1,
    title: 'Home',
    href: '/',
  },
  {
    id: 2,
    title: 'About Us',
    href: '/aboutus',
  },
  {
    id: 3,
    title: 'Services',
    // href: 'th',
    submenu: [
      {
        id: 1,
        title: 'Generator Maintenance',
        href: '/services/generator',
      },
      {
        id: 2,
        title: 'Motor Maintenance',
        href: '/services/motormaintain',
      },
      {
        id: 3,
        title: 'Pump Repair For All Types',
        href: '/services/pumprepair',
      },
      {
        id: 4,
        title: 'Industrial & Medical Compressors Maintenance',
        href: '/services/industrial',
      },
    ],
  },
  {
    id: 4,
    title: 'Blogs',
    href: '/blogs',
  },

  {
    id: 5,
    title: 'Support',
    href: '/support',
  },

  {
    id: 6,
    title: 'Privacy Policy',
    href: '/privacypolicy',
  },
  {
    id: 7,
    title: 'Contact Us',
    href: '/contactus',
  },
];
