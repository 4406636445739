import { Box } from '@chakra-ui/react';
import React from 'react';
import { Helmet } from 'react-helmet';
import MobileContactUs from '../components/MobileContactUs';
import Contacttus from '../components/Contacttus';

function ContactUs() {
  return (
    <Box>
      <Helmet>
        <title>Contact Us | ECIWS</title>
        <meta
          name="description"
          content="Got questions? We've got answers. Contact ECIWS to get in touch with our experienced professionals and discover how our inspection software can help you achieve greater success in your manufacturing processes. Whether you're looking for top-quality inspections, audits, testing, or certifications, we're here to provide the solutions you need. Don't hesitate to reach out and take the first step towards a brighter future."
        />
        <meta property="og:title" content="Contact Us | ECIWS" />
        <meta
          property="og:description"
          content="Got questions? We've got answers. Contact ECIWS to get in touch with our experienced professionals and discover how our inspection software can help you achieve greater success in your manufacturing processes. Whether you're looking for top-quality inspections, audits, testing, or certifications, we're here to provide the solutions you need. Don't hesitate to reach out and take the first step towards a brighter future."
        />
      </Helmet>
      <Contacttus />
      {/* <MobileContactUs /> */}
    </Box>
  );
}

export default ContactUs;
