import { Box, Center, FormControl } from '@chakra-ui/react';
import React from 'react';
import Form from './reusable/Formcomp';
import { useRef, useState } from 'react';
import emailjs from '@emailjs/browser';
import { useToast } from '@chakra-ui/react';
import { useNavigate } from 'react-router-dom';

const Feedback = () => {
  const [fname, setfname] = useState();
  const [email, setemail] = useState();
  const [textarea, settextarea] = useState();
  const navigate = useNavigate();
  // const isError = contact === '';
  const handleName = e => {
    setfname(e.target.value);
  };

  const handleEmail = e => {
    setemail(e.target.value);
  };
  const handleTextarea = e => {
    settextarea(e.target.value);
  };

  const form = useRef();

  // toast
  const toast = useToast();

  const sendEmail = e => {
    e.preventDefault();
    if (!fname || !email || !textarea) {
      // console.log('error');
      toast({
        title: 'error',
        description: 'fill all input fields',
        status: 'error',
        position: 'top',
        duration: 7000,
        isClosable: true,
      });
    } else {
      emailjs
        .sendForm(
          'service_jxggc25',
          'template_1bpiuwi',
          form.current,
          'eeHXWTnjXvNHD1z8B'
        )
        .then(
          result => {
            e.target.reset();
            navigate('/thankyou');
            toast({
              title: 'Submitted Successfully',
              description: 'Your feedback is valuable to me.',
              status: 'success',
              position: 'top',
              duration: 7000,
              isClosable: true,
            });

            console.log('Message Success');
          },
          error => {
            console.log(error.text);
          }
        );
    }
    // e.preventDefault();

    // emailjs
    //   .sendForm(
    //     'service_jxggc25',
    //     'template_1bpiuwi',
    //     form.current,
    //     'eeHXWTnjXvNHD1z8B'
    //   )
    //   .then(
    //     result => {
    //       console.log(result.text);
    //       e.target.reset();
    //       toast({
    //         title: 'Submitted Successfully',
    //         description: 'Your feedback is valuable to me.',
    //         status: 'success',
    //         position: 'top',
    //         duration: 7000,
    //         isClosable: true,
    //       });

    //       console.log('Message Success');
    //     },
    //     error => {
    //       console.log(error.text);
    //     }
    //   );
  };
  return (
    <Center w={'full'}>
      <Box maxW={'1920px'} py={'30px'}>
        {/* <Form
          heading={'Feedback Form'}
          description={
            "We're constantly striving to improve our services and your feedback is crucial to us. Please take a moment to share your thoughts with us."
          }
          Name={'Full Name'}
          email={'Email'}
          textareas={'Share your Feedback here'}
          sideimage={'feedback.png'}
        /> */}
        <FormControl
          as={'form'}
          ref={form}
          onSubmit={sendEmail}
          // onChange={handleName}
        >
          <Form
            heading={'Feedback Form'}
            textarea={'message'}
            description={
              "We're constantly striving to improve our services and your feedback is crucial to us. Please take a moment to share your thoughts with us."
            }
            Name={'Full Name'}
            email={'Email'}
            buttonText={'Submit'}
            textareas={'Share your Feedback here'}
            sideimage={'/forms/feedback.png'}
            // for emailjs
            fname={'user_name'}
            mail={'user_email'}
            namevalue={fname}
            handleName={handleName}
            emailvalue={email}
            handleEmail={handleEmail}
            textareavalue={textarea}
            handleTextarea={handleTextarea}
          />
        </FormControl>
      </Box>
    </Center>
  );
};

export default Feedback;
