import React from 'react';
import { Box, Center } from '@chakra-ui/react';
import { Hero } from '../components/hero';
import { MachinesTested } from '../components/machinesTested';
import { MachinesInspected } from '../components/machinesInspected';
import { Faqs } from '../components/faqs';
import { Footer } from '../components/footer';
import CoreServices from '../components/coreServices';
import { Helmet } from 'react-helmet';

function App() {
  return (
    <Box zIndex={'100000'} bgColor={'#F4F9FF'}>
      <Helmet>
        <title>Home | ECIWS</title>
        <meta
          name="description"
          content="At ECIWS, we believe in empowering manufacturers with top-quality inspections, audits, testing, and certifications. Our experienced professionals are committed to ensuring your success by meeting the highest industry standards. Discover how our inspection software can help take your manufacturing processes to the next level. Join us on this journey towards excellence."
        />
        <meta property="og:title" content="Home | ECIWS" />
        <meta
          property="og:description"
          content="At ECIWS, we believe in empowering manufacturers with top-quality inspections, audits, testing, and certifications. Our experienced professionals are committed to ensuring your success by meeting the highest industry standards. Discover how our inspection software can help take your manufacturing processes to the next level. Join us on this journey towards excellence."
        />
      </Helmet>
      <Center w={'full'} flexDirection={'column'}>
        <Hero />
        <MachinesTested />
        <CoreServices />
        <MachinesInspected />
        <Faqs />
      </Center>
    </Box>
  );
}

export default App;
