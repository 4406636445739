import React, { useState } from 'react';
import {
  Box,
  Button,
  Center,
  Flex,
  FormControl,
  FormErrorMessage,
  Image,
  Input,
  Text,
  Textarea,
} from '@chakra-ui/react';
import { useRef } from 'react';
import emailjs from '@emailjs/browser';

const Form = ({
  heading,
  description,
  Name,
  email,
  phoneNumber,
  comments,
  textareas,
  sideimage,
  fname,
  mail,
  textarea,
  mobileNumber,
  commenttext,
  buttonText,
  // feedback
  namevalue,
  handleName,
  emailvalue,
  handleEmail,
  textareavalue,
  handleTextarea,
  // inquiry
  numbervalue,
  handlenumber,
  msgvalue,
  handlemsg,
}) => {
  return (
    <Box
      display={'flex'}
      flexDir={'row'}
      gap={'min(13.5vw,260px)'}
      w={'100%'}
      fontFamily={'Montserrat'}
    >
      <Box>
        <Center width={{ base: '90%', md: '100%' }} pl={'min(5.20vw,100px)'}>
          <Box color={'#000938'} display={'flex'} flexDir={'column'}>
            <Box display={'flex'} flexDir={'column'}>
              <Text
                fontWeight={'600'}
                fontSize={{ base: '5vw', lg: 'min(1.82vw,35px)' }}
                lineHeight={'min(3.90vw,75px)'}
              >
                {heading}
              </Text>
              <Text
                fontWeight={'400'}
                fontSize={{ base: '3.9vw', lg: 'min(1.19vw,23px)' }}
                lineHeight={{ base: '5vw', lg: 'min(1.56vw,30px)' }}
                textAlign={'justify'}
                letterSpacing={'0.04em'}
                pt={'15px'}
              >
                {description}
              </Text>
            </Box>
            {/* input fields */}
            <Box
              pt={'40px'}
              display={'flex'}
              flexDir={'column'}
              gap={{ base: 'min(11vw,50px)', lg: 'min(3.125vw,60px)' }}
            >
              {/* <FormControl as={'form'} ref={form} onSubmit={sendEmail}> */}
              <Box>
                <Text
                  fontStyle={'normal'}
                  fontWeight={'500'}
                  fontSize={{ lg: 'min(0.86vw,16.53px)' }}
                  lineHeight={{ lg: 'min(0.90vw,17.35px)' }}
                  letterSpacing={'4%'}
                  color={'#8F97C5'}
                >
                  {Name}
                </Text>
                <Input
                  variant="flushed"
                  height={{ '3xl': '46px', md: '2vw' }}
                  borderBottom={'1px solid'}
                  borderColor={'#A4AEE4'}
                  borderRadius={'0px'}
                  // width={{ '3xl': '933px', md: '48.50vw' }}
                  padding={'0px'}
                  as={'input'}
                  type="text"
                  value={namevalue}
                  onChange={handleName}
                />
              </Box>
              <Box>
                <Text
                  fontStyle={'normal'}
                  fontWeight={'500'}
                  fontSize={{ lg: 'min(0.86vw,16.53px)' }}
                  lineHeight={{ lg: 'min(0.90vw,17.35px)' }}
                  letterSpacing={'4%'}
                  color={'#8F97C5'}
                >
                  {email}
                </Text>
                <Input
                  variant="flushed"
                  height={{ '3xl': '46px', md: '2vw' }}
                  borderBottom={'1px solid'}
                  borderColor={'#A4AEE4'}
                  borderRadius={'0px'}
                  // width={{ '3xl': '933px', md: '48.50vw' }}
                  padding={'0px'}
                  as={'input'}
                  type="email"
                  name={mail}
                  value={emailvalue}
                  onChange={handleEmail}
                />
              </Box>
              {phoneNumber && (
                <Box>
                  <Text
                    fontStyle={'normal'}
                    fontWeight={'500'}
                    fontSize={{ lg: 'min(0.86vw,16.53px)' }}
                    lineHeight={{ lg: 'min(0.90vw,17.35px)' }}
                    letterSpacing={'4%'}
                    color={'#8F97C5'}
                  >
                    {phoneNumber}
                  </Text>
                  <Input
                    variant="flushed"
                    height={{ '3xl': '46px', md: '2vw' }}
                    borderBottom={'1px solid'}
                    borderColor={'#A4AEE4'}
                    borderRadius={'0px'}
                    // width={{ '3xl': '933px', md: '48.50vw' }}
                    padding={'0px'}
                    as={'input'}
                    type="number"
                    name={mobileNumber}
                    value={numbervalue}
                    onChange={handlenumber}
                  />
                </Box>
              )}
              {comments && commenttext && (
                <Box>
                  <Text
                    fontStyle={'normal'}
                    fontWeight={'500'}
                    fontSize={{ lg: 'min(0.86vw,16.53px)' }}
                    lineHeight={{ lg: 'min(0.90vw,17.35px)' }}
                    letterSpacing={'4%'}
                    color={'#8F97C5'}
                  >
                    {comments}
                  </Text>
                  <Input
                    // variant="flushed"
                    height={{ '3xl': '46px', md: '2vw' }}
                    border="none"
                    borderBottom={'1px solid'}
                    borderColor={'#A4AEE4'}
                    borderRadius={'0px'}
                    // width={{ '3xl': '933px', md: '48.50vw' }}
                    padding={'0px'}
                    as={'input'}
                    type="text"
                    value={msgvalue}
                    onChange={handlemsg}
                    // type={commenttext}
                  />
                </Box>
              )}
              {textareas && (
                <Box>
                  <Text
                    fontStyle={'normal'}
                    fontWeight={'500'}
                    fontSize={{ lg: 'min(0.86vw,16.53px)' }}
                    lineHeight={{ lg: 'min(0.90vw,17.35px)' }}
                    letterSpacing={'4%'}
                    color={'#8F97C5'}
                    pb={{ lg: 'min(1.82vw,35px)' }}
                  >
                    {textareas}
                  </Text>
                  <Textarea
                    h={{ lg: 'min(11.87vw,228px)' }}
                    // width={{ '3xl': '933px', md: '48.50vw' }}
                    bg={'#F0F2FA'}
                    name={textarea}
                    value={textareavalue}
                    onChange={handleTextarea}
                  />
                </Box>
              )}
              {/* button */}
              <Button
                w={'min(9vw,164px)'}
                px={{ base: '60px', md: 'min(1.4vw,20px)' }}
                py={{ base: '20px', md: 'min(1.4vw,20px)' }}
                bg={'#354A80'}
                color={'white'}
                _hover={{
                  color: '#354A80',
                  bg: 'white',
                  border: '1px solid',
                  borderColor: '#354A80',
                }}
                type="submit"
                value="send"
              >
                {buttonText}
              </Button>
              {/* </FormControl> */}
            </Box>
          </Box>
        </Center>
      </Box>
      <Box
        display={{ base: 'none', md: 'flex' }}
        pr={'5rem'}
        justifyContent={'end'}
        w={'45%'}
        m={'auto'}
      >
        {/* <Center> */}
        <Image
          // src="contacteci.png"
          src={sideimage}
          w={'min(26.04vw,500px)'}
          alignItems={'center'}
          borderRadius={'15px'}
          display={'flex'}
        />
        {/* </Center> */}
      </Box>
    </Box>
  );
};

export default Form;
