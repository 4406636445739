import { Box, Center, Image, Link, Text } from '@chakra-ui/react';
import React from 'react';

export const Footer = () => {
  const footerLinks = [
    {
      id: 1,
      title: 'Work',
      basefontSize: '4.603125vw',
      baselineHeight: '9.20vw',
      mdfontSize: ' 0.9375vw ',
      mdlineHeight: ' 1.87vw ',
      threefontSize: '   1.125rem ',
      threelineHeight: '   2.25rem ',
      href: '#',
      color: '#ffffff',
    },
    {
      id: 2,
      basefontSize: '4.09vw',
      baselineHeight: '3.13vw',
      title: 'Home',
      mdfontSize: ' 0.8335vw ',
      mdlineHeight: ' 0.65vw ',
      threefontSize: '   1rem ',
      threelineHeight: '   0.779rem ',
      href: '/',
      color: '#FFF',
    },
    {
      id: 3,
      basefontSize: '4.09vw',
      baselineHeight: '3.13vw',
      title: 'About Us',
      mdfontSize: ' 0.8335vw ',
      mdlineHeight: ' 0.65vw ',
      threefontSize: '   1rem ',
      threelineHeight: '   0.779rem ',
      href: '/aboutus',
      color: '#FFF',
    },
    {
      id: 4,
      basefontSize: '4.09vw',
      baselineHeight: '3.13vw',
      title: 'Services',
      mdfontSize: ' 0.8335vw ',
      mdlineHeight: ' 0.65vw ',
      threefontSize: '   1rem ',
      threelineHeight: '   0.779rem ',
      href: '/services/generator',
      color: '#FFF',
    },
    {
      id: 4,
      basefontSize: '4.09vw',
      baselineHeight: '3.13vw',
      title: 'Blogs',
      mdfontSize: ' 0.8335vw ',
      mdlineHeight: ' 0.65vw ',
      threefontSize: '   1rem ',
      threelineHeight: '   0.779rem ',
      href: '/blogs',
      color: '#FFF',
    },
  ];
  const footerLinksTwo = [
    {
      id: 1,
      basefontSize: '4.603125vw',
      baselineHeight: '9.20vw',
      title: 'Quicklinks',
      mdfontSize: ' 0.9375vw ',
      mdlineHeight: ' 1.87vw ',
      threefontSize: '   1.125rem ',
      threelineHeight: '   2.25rem ',
      href: '#',
      color: '#ffffff',
    },
    {
      id: 2,
      basefontSize: '4.09vw',
      baselineHeight: '3.13vw',
      title: 'Privacy Policy',
      mdfontSize: ' 0.8335vw ',
      mdlineHeight: ' 0.65vw ',
      threefontSize: '   1rem ',
      threelineHeight: '   0.779rem ',
      href: '/privacypolicy',
      color: '#FFF',
    },
    {
      id: 3,
      basefontSize: '4.09vw',
      baselineHeight: '3.13vw',
      title: 'Terms & Conditions',
      mdfontSize: ' 0.8335vw ',
      mdlineHeight: ' 0.65vw ',
      threefontSize: '   1rem ',
      threelineHeight: '   0.779rem ',
      href: '',
      color: '#FFF',
    },
    {
      id: 3,
      basefontSize: '4.09vw',
      baselineHeight: '3.13vw',
      title: 'Support',
      mdfontSize: ' 0.8335vw ',
      mdlineHeight: ' 0.65vw ',
      threefontSize: '   1rem ',
      threelineHeight: '   0.779rem ',
      href: '/support',
      color: '#FFF',
    },
  ];
  const footerLinksThree = [
    {
      id: 1,
      basefontSize: '4.603125vw',
      baselineHeight: '9.20vw',
      title: 'Socials',
      mdfontSize: ' 0.9375vw ',
      mdlineHeight: ' 1.87vw ',
      threefontSize: '   1.125rem ',
      threelineHeight: '   2.25rem ',
      href: '',
      color: '#ffffff',
    },
    {
      id: 2,
      basefontSize: '4.09vw',
      baselineHeight: '3.13vw',
      title: 'Facebook',
      mdfontSize: ' 0.8335vw ',
      mdlineHeight: ' 0.65vw ',
      threefontSize: '   1rem ',
      threelineHeight: '   0.779rem ',
      href: '',
      color: '#FFF',
    },
    {
      id: 3,
      basefontSize: '4.09vw',
      baselineHeight: '3.13vw',
      title: 'Instagram',
      mdfontSize: ' 0.8335vw ',
      mdlineHeight: ' 0.65vw ',
      threefontSize: '   1rem ',
      threelineHeight: '   0.779rem ',
      href: '',
      color: '#FFF',
    },
    {
      id: 3,
      basefontSize: '4.09vw',
      baselineHeight: '3.13vw',
      title: 'LinkedIn',
      mdfontSize: ' 0.8335vw ',
      mdlineHeight: ' 0.65vw ',
      threefontSize: '   1rem ',
      threelineHeight: '   0.779rem ',
      href: '',
      color: '#FFF',
    },
  ];
  const socialLinks = [
    {
      id: 1,
      href: '#footer',
      baseWidth: '4.14vw',
      mdWidth: '1.46vw',
      src: '/facebook.png',
    },
    {
      id: 2,
      href: '#footer',
      baseWidth: '5.387vw',
      mdWidth: '1.75vw',
      src: '/twitter.png',
    },
    {
      id: 3,
      href: '#footer',
      baseWidth: '4.71vw',
      mdWidth: '1.667vw',
      src: '/instagram.png',
    },
  ];

  return (
    <Center w={'full'} display={'flex'} flexDirection={'column'}>
      <Center w={'full'} bgColor={'#000938'}>
        <Box
          w={'full'}
          maxW={'1920px'}
          display={'flex'}
          flexDir={{ base: 'column', md: 'row' }}
          justifyContent={'space-between'}
          alignItems={{ base: 'start', md: 'center' }}
          px={{ base: '6.5625vw', md: '5.45vw', '3xl': '6.5625rem' }}
          pt={{ base: '14.375vw', md: '4.1675vw', '3xl': '5rem' }}
          pb={{ base: '31.25vw', md: '6.25vw', '3xl': '7.5rem' }}
          bgColor={'#000938'}
          gap={{ base: '12.75vw', md: '0' }}
        >
          <Box
            display={'flex'}
            flexDirection={'column'}
            gap={{ base: '7.03125vw', md: '3.1vw', '3xl': '3.75rem' }}
            w={{ md: '26.667vw', '3xl': '32rem' }}
          >
            <Image
              w={{ base: '42.44vw', md: '12.5vw', '3xl': '15rem' }}
              src="/footer/logo.png"
            />
            <Box
              display={'flex'}
              flexDir={'column'}
              gap={'1rem'}
              color={'#AEDCE7'}
            >
              <Text> +966 59 464 3423</Text>
              <Text>info@eciws.com</Text>
              <Text w={'85%'}>
                {' '}
                Dhahran Jubail Branch Rd, Al Khodaryah, Dammam 32435, Saudi
                Arabia
              </Text>
            </Box>
          </Box>
          <Box
            display={'flex'}
            flexDir={{ base: 'column', md: 'row' }}
            gap={{ base: '15.81vw', md: '0' }}
            justifyContent={'space-between'}
            w={{ md: '21.811vw', '3xl': '419px' }}
          >
            <Box
              display={'flex'}
              flexDirection={'column'}
              gap={{ base: '6.39vw', md: '2.345vw', '3xl': '2.813rem' }}
              fontWeight={'500'}
            >
              {footerLinks.map(data => {
                return (
                  <Link
                    fontFamily="Source Sans Pro"
                    key={data.id}
                    fontSize={{
                      base: data.basefontSize,
                      md: data.mdfontSize,
                      '3xl': data.threefontSize,
                    }}
                    lineHeight={{
                      base: data.baselineHeight,
                      md: data.mdlineHeight,
                      '3xl': data.threelineHeight,
                    }}
                    href={data.href}
                    color={data.color}
                    _hover={{ textDecoration: 'none' }}
                  >
                    {data.title}
                  </Link>
                );
              })}
            </Box>
            <Box
              display={'flex'}
              flexDirection={'column'}
              gap={{ base: '6.39vw', md: '2.345vw', '3xl': '2.813rem' }}
              fontWeight={'500'}
            >
              {footerLinksTwo.map(data => {
                return (
                  <Link
                    fontFamily="Source Sans Pro"
                    key={data.id}
                    fontSize={{
                      base: data.basefontSize,
                      md: data.mdfontSize,
                      '3xl': data.threefontSize,
                    }}
                    lineHeight={{
                      base: data.baselineHeight,
                      md: data.mdlineHeight,
                      '3xl': data.threelineHeight,
                    }}
                    href={data.href}
                    color={data.color}
                    _hover={{ textDecoration: 'none' }}
                  >
                    {data.title}
                  </Link>
                );
              })}
            </Box>
            <Box
              display={'flex'}
              flexDirection={'column'}
              gap={{ base: '6.39vw', md: '2.345vw', '3xl': '2.813rem' }}
              fontWeight={'500'}
            >
              {footerLinksThree.map(data => {
                return (
                  <Link
                    fontFamily="Source Sans Pro"
                    key={data.id}
                    fontSize={{
                      base: data.basefontSize,
                      md: data.mdfontSize,
                      '3xl': data.threefontSize,
                    }}
                    lineHeight={{
                      base: data.baselineHeight,
                      md: data.mdlineHeight,
                      '3xl': data.threelineHeight,
                    }}
                    href={data.href}
                    color={data.color}
                    _hover={{ textDecoration: 'none' }}
                  >
                    {data.title}
                  </Link>
                );
              })}
            </Box>
          </Box>
          <Box
            display={'flex'}
            justifyContent={'center'}
            flexDirection={'column'}
            gap={{ base: '7.03125vw', md: '3.1vw', '3xl': '3.75rem' }}
            w={{ md: '22.54vw', '3xl': '433px' }}
          >
            <iframe
              border-radius="10px"
              title="map"
              src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3572.246181301475!2d50.043046200000006!3d26.447792399999997!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x3e49fdb95118edf7%3A0x7f596cba0eadc07d!2sECIWS!5e0!3m2!1sen!2sin!4v1687001637455!5m2!1sen!2sin"
              height="225"
              allowfullscreen=""
              loading="lazy"
              referrerpolicy="no-referrer-when-downgrade"
            ></iframe>
          </Box>
        </Box>
      </Center>
      <Center w={'full'} bgColor={'#132373'}>
        <Box
          w={'full'}
          maxW={'1920px'}
          bgColor={'#132373'}
          px={{ base: '9.375vw', md: '5.45vw', '3xl': '6.5625rem' }}
          py={{ base: '3.125vw', md: '0.675vw', '3xl': '0.8rem' }}
          display={'flex'}
          flexDir={{ base: 'column', md: 'row' }}
          justifyContent={'space-between'}
          alignItems={'center'}
          gap={{ base: '3.125vw', md: '0' }}
        >
          <Text
            color={'#C0C1C2'}
            fontFamily="Source Sans Pro"
            fontWeight={'600'}
            fontSize={{ base: '3.5vw', md: '0.73vw', '3xl': '0.875rem' }}
            lineHeight={{ base: '4.29vw', md: '0.9vw', '3xl': '1.072rem' }}
          >
            © 2019-2022, All Rights Reserved ECIWS.
          </Text>
          <Text color={'#C0C1C2'} fontStyle={'italic'}>
            Brought by{' '}
            <Link
              href="https://www.websleak.com/"
              textDecoration={'underline'}
              fontWeight={'600'}
            >
              websleak
            </Link>
          </Text>
        </Box>
      </Center>
    </Center>
  );
};
