import React from 'react';
import { Box, Center } from '@chakra-ui/react';
import { Footer } from '../components/footer';
import { AboutHero } from '../components/aboutHero';
import WhatWeOffer from '../components/whatWeOffer';
import { FeaturesSlider } from '../components/featuresSlider';
import OurStory from '../components/ourStory';
import { Helmet } from 'react-helmet';
import Powerful from '../components/Powerful';
import Contacttus from '../components/Contacttus';
import Feedback from '../components/Feedback';
import Inquiry from '../components/Inquiry';
// import Slidetext from '../components/Slidetext';

function AboutUs() {
  return (
    <Box bgColor={'#F4F9FF'}>
      <Helmet>
        <title>About | ECIWS</title>
        <meta
          name="description"
          content="Learn about ECIWS, the world's leading inspection software for manufacturers. Discover our mission, values, and the experienced professionals who make it all possible. Find out how our commitment to excellence and high industry standards can benefit your business. Join us in our journey towards better manufacturing processes and a brighter future."
        />
        <meta property="og:title" content="About | ECIWS" />
        <meta
          property="og:description"
          content="Learn about ECIWS, the world's leading inspection software for manufacturers. Discover our mission, values, and the experienced professionals who make it all possible. Find out how our commitment to excellence and high industry standards can benefit your business. Join us in our journey towards better manufacturing processes and a brighter future."
        />
      </Helmet>
      <Box fontFamily={'Source Sans Pro'} bg={'#F4F9FF'} mb={'100px'}>
        <AboutHero />
        <OurStory />
        <WhatWeOffer />
        {/* Competitive Pricing is FeaturesSlider */}

        <FeaturesSlider />

        {/* <Slidetext /> */}

        {/* <Box display={{ lg: 'none' }}>helll</Box> */}
        {/* commented on 7-7 */}
        {/* <Powerful /> */}
        {/* <Powerful />
        <Contacttus />
        <Feedback />
        <Inquiry /> */}
        {/* <Footer /> */}
      </Box>
    </Box>
  );
}

export default AboutUs;
