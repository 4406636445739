import {
  Box,
  Button,
  Center,
  Flex,
  FormControl,
  Image,
  Input,
  Text,
  Textarea,
} from '@chakra-ui/react';
// import React from 'react';
import Form from './reusable/Formcomp';
import React, { useRef, useState } from 'react';
import emailjs from '@emailjs/browser';
import { useToast } from '@chakra-ui/react';
import { useNavigate } from 'react-router-dom';

const Contacttus = () => {
  // validation

  const [name, setname] = useState();
  const [Email, setEmail] = useState();
  const [msg, setmsg] = useState();
  const navigate = useNavigate();

  const handleName = e => {
    setname(e.target.value);
  };
  const handleEmail = e => {
    setEmail(e.target.value);
  };
  const handlemsg = e => {
    setmsg(e.target.value);
  };
  // for email-js
  const form = useRef();
  //toast

  const toast = useToast();

  const sendEmail = e => {
    e.preventDefault();
    if (!name || !Email || !msg) {
      toast({
        title: 'error',
        description: 'fill all input fields',
        status: 'error',
        position: 'top',
        duration: 7000,
        isClosable: true,
      });
    } else {
      emailjs
        .sendForm(
          'service_f2ax2lj',
          'template_u5odoxc',
          form.current,
          'eeHXWTnjXvNHD1z8B'
        )
        .then(
          result => {
            console.log(result.text);
            navigate('/thankyou');
            // clear form
            e.target.reset();
            console.log('Message Success');
            toast({
              title: 'Submitted Successfully',
              description: 'We will contact you soon',
              status: 'success',
              position: 'top',
              duration: 7000,
              isClosable: true,
            });
          },
          error => {
            console.log(error.text);
          }
        );
    }
  };
  return (
    <Center w={'full'} display={'flex'} flexDirection={'column'} bg={'#F4F9FF'}>
      <Box maxW={'1920px'} width={'100%'} fontFamily={'Montserrat'}>
        <Box
          display={'flex'}
          flexDir={'row'}
          justifyContent={'center'}
          gap={'min(100px,7vw)'}
          // width={'100%'}
          // w={'100vw'}
          // h={'80vw'}
        >
          <Center
            width={{ base: '100%', md: '%' }}
            pl={'min(5.20vw,100px)'}
            py={{ base: 'min(20vw,120px)', md: '0px' }}
          >
            <Box color={'#000938'} display={'flex'} flexDir={'column'}>
              <Box
                display={'flex'}
                flexDir={'column'}
                justifyContent={'center'}
                w={'90%'}
              >
                <Text
                  fontWeight={'600'}
                  fontSize={{ base: '5vw', md: 'min(1.82vw,35px)' }}
                  lineHeight={'min(3.90vw,75px)'}
                >
                  Contact Us
                </Text>
                <Text
                  fontWeight={'400'}
                  fontSize={{ base: '3.9vw', md: 'min(1.19vw,23px)' }}
                  lineHeight={{ base: '5vw', md: 'min(1.56vw,30px)' }}
                  textAlign={'justify'}
                  letterSpacing={'0.04em'}
                  pt={'15px'}
                >
                  Need to reach us? Use the contact form below and we&apos;ll
                  get back to you as soon as possible.
                </Text>
              </Box>

              <Box
                pt={'40px'}
                display={'flex'}
                flexDir={'column'}
                gap={{ md: 'min(3.125vw,60px)' }}
              >
                <FormControl
                  pt={'40px'}
                  display={'flex'}
                  flexDir={'column'}
                  justifyContent={'center'}
                  gap={{ base: '8vw', md: 'min(3.125vw,60px)' }}
                  as={'form'}
                  ref={form}
                  onSubmit={sendEmail}
                >
                  <Box>
                    <Text
                      fontStyle={'normal'}
                      fontWeight={'500'}
                      fontSize={{ md: 'min(0.86vw,16.53px)' }}
                      lineHeight={{ md: 'min(0.90vw,17.35px)' }}
                      letterSpacing={'4%'}
                      color={'#8F97C5'}
                    >
                      Full Name
                    </Text>
                    <Input
                      variant="flushed"
                      height={{ '3xl': '46px', md: '2vw' }}
                      borderBottom={'1px solid'}
                      borderColor={'#A4AEE4'}
                      borderRadius={'0px'}
                      width={{ '3xl': '771px', md: '40.50vw', base: '90%' }}
                      padding={'0px'}
                      as={'input'}
                      type="text"
                      name="user_name"
                      value={name}
                      onChange={handleName}
                    />
                  </Box>
                  <Box>
                    <Text
                      fontStyle={'normal'}
                      fontWeight={'500'}
                      fontSize={{ md: 'min(0.86vw,16.53px)' }}
                      lineHeight={{ md: 'min(0.90vw,17.35px)' }}
                      letterSpacing={'4%'}
                      color={'#8F97C5'}
                    >
                      Email
                    </Text>
                    <Input
                      variant="flushed"
                      height={{ '3xl': '46px', md: '2vw' }}
                      borderBottom={'1px solid'}
                      borderColor={'#A4AEE4'}
                      borderRadius={'0px'}
                      width={{ '3xl': '771px', md: '40.50vw', base: '90%' }}
                      padding={'0px'}
                      as={'input'}
                      type="email"
                      name="user_email"
                      value={Email}
                      onChange={handleEmail}
                    />
                  </Box>
                  <Box>
                    <Text
                      fontStyle={'normal'}
                      fontWeight={'500'}
                      fontSize={{ md: 'min(0.86vw,16.53px)' }}
                      lineHeight={{ md: 'min(0.90vw,17.35px)' }}
                      letterSpacing={'4%'}
                      color={'#8F97C5'}
                      pb={{ md: 'min(1.82vw,35px)' }}
                    >
                      Message
                    </Text>
                    <Textarea
                      w={{ md: '95%', base: '90%' }}
                      h={{ md: 'min(11.87vw,228px)' }}
                      bg={'#F0F2FA'}
                      name="message"
                      value={msg}
                      onChange={handlemsg}
                    />
                  </Box>

                  <Button
                    w={'min(9vw,164px)'}
                    px={{ base: '60px', md: '20px' }}
                    py={{ base: '20px', md: '30px' }}
                    bg={'#354A80'}
                    color={'white'}
                    _hover={{
                      color: '#354A80',
                      bg: 'white',
                      border: '1px solid',
                      borderColor: '#354A80',
                      scaleX: '1',
                      overflow: 'hidden',
                    }}
                    type="submit"
                    value="send"
                    fontFamily={'Montserrat'}
                  >
                    Submit
                  </Button>
                </FormControl>
              </Box>
            </Box>
          </Center>
          <Box
            w={'100%'}
            // w={'110%'}
            bg={'#000938'}
            h={{ base: '100%', md: 'min(60vw,1006px)' }}
            display={{ base: 'none', md: 'flex' }}
            justifyContent={'Center'}
            alignItems={'center'}
          >
            <Image
              src="/forms/contacteci.png"
              w={'min(26.04vw,500px)'}
              borderRadius={'15px'}
              // h={'min(26.04vw,500px)'}
            />
          </Box>
        </Box>

        {/* <Form
          heading="Contact Us"
          description={
            "Need to reach us? Use the contact form below and we'll get back to you as soon as possible."
          }
          Name={'Full Name'}
          email={'Email'}
          textareas={'Message'}
          sideimage={'/forms/contacteci.png'}
        /> */}
      </Box>
    </Center>
  );
};
// src="contacteci.png"

export default Contacttus;
