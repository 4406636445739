import {
  Box,
  Flex,
  Text,
  IconButton,
  Button,
  Stack,
  Collapse,
  Icon,
  Link,
  Popover,
  PopoverTrigger,
  PopoverContent,
  useColorModeValue,
  useBreakpointValue,
  useDisclosure,
  Center,
  Image,
  Input,
  Menu,
  MenuList,
  MenuItem,
  MenuButton,
  InputGroup,
  InputLeftElement,
} from '@chakra-ui/react';

import {
  HamburgerIcon,
  CloseIcon,
  ChevronDownIcon,
  ChevronRightIcon,
  PhoneIcon,
  SearchIcon,
} from '@chakra-ui/icons';
import React, { useState } from 'react';
import { items } from './navbarData/items';
import { mobileitems } from './navbarData/mobileitems';
import { NavLink, useLocation } from 'react-router-dom';

// import './Navbar.css';
// import './Navbar.css'

export const Navbar = () => {
  const DesktopNav = () => {
    const location = useLocation();

    return (
      <Stack direction={'row'} spacing={{ md: '1.7vw', '3xl': '48px' }}>
        {items.map(navItem => (
          <Box zIndex={'100000'} key={navItem.id}>
            <Popover trigger={'hover'} placement={'bottom-start'}>
              <PopoverTrigger>
                <NavLink
                  // className={({ isActive }) =>
                  //   isActive ? 'active-class' :'inactive-class'
                  // }
                  to={navItem.href ?? '#'}
                >
                  <Box display={'flex'} flexDir={'row'}>
                    <Text
                      p={2}
                      fontSize={{ md: '0.93vw', '3xl': '1rem' }}
                      fontWeight={'600'}
                      _hover={{ textDecoration: 'none', color: '#283681' }}
                      // _active={{ color: '#0C99B0' }}
                      color={
                        location.pathname === navItem.href
                          ? '#283681'
                          : '#7982B0'
                      }
                    >
                      {navItem.title}
                    </Text>
                    {navItem.title === 'Services' ? (
                      <Image src="/chevron.svg"></Image>
                    ) : (
                      <div></div>
                    )}
                  </Box>
                </NavLink>
              </PopoverTrigger>

              {navItem.submenu && (
                <PopoverContent
                  border={0}
                  boxShadow={'xl'}
                  bg={'#E0E8F3'}
                  z-index={'10000'}
                  p={{ md: '1.5vw', '3xl': '28.815px' }}
                  rounded={'1.0416vw'}
                  minW={'20vw'}
                  maxH={'360px'}
                  display={'flex'}
                  overflowWrap={'normal'}
                >
                  <Stack display={'flex'}>
                    {navItem.submenu.map(submenu => (
                      <DesktopSubNav submenu={submenu} />
                    ))}
                  </Stack>
                </PopoverContent>
              )}
            </Popover>
          </Box>
        ))}
      </Stack>
    );
  };

  const DesktopSubNav = ({ submenu }) => {
    return (
      <Stack direction={'column'} spacing={1} zIndex={'10000000'}>
        <Box zIndex={'10000000'}>
          <Popover
            zIndex={'10000000'}
            trigger={'hover'}
            placement={'right-start'}
          >
            <PopoverTrigger>
              <Link
                zIndex={'10000000'}
                href={submenu.href ?? '#'}
                role={'group'}
                display={'block'}
                p={'0.3vw'}
                rounded={'md'}
                
                // _hover={{ bgColor: 'black' }}
              >
                <Stack direction={'row'} align={'center'}>
                  <Box>
                    <Text
                      transition={'all .3s ease'}
                      // _hover={{ color: 'black' }}
                      fontWeight={400}
                      opacity={'1'}
                    >
                      {submenu.title}
                    </Text>
                  </Box>
                  <Flex
                    transition={'all .3s ease'}
                    transform={'translateX(-10px)'}
                    opacity={0}
                    _groupHover={{
                      opacity: '100%',
                      transform: 'translateX(0)',
                    }}
                    justify={'flex-end'}
                    align={'center'}
                    flex={1}
                  >
                    <Icon
                      display={submenu.subSubmenu ? 'block' : 'none'}
                      color={'#000'}
                      w={5}
                      h={5}
                      as={ChevronRightIcon}
                    />
                  </Flex>
                </Stack>
              </Link>
            </PopoverTrigger>

            {/* {submenu.subSubmenu && (
              <PopoverContent
                border={0}
                boxShadow={'xl'}
                bg={'#DBEFF3'}
                p={4}
                ml={'0.59895vw'}
                rounded={'1.0416vw'}
                minW={'20vw'}
              >
                {submenu.subSubmenu.map(subSubmenu => {
                  return (
                    <Stack direction={'column'} spacing={'2rem'}>
                      <Link
                        href={subSubmenu.href}
                        key={subSubmenu.id}
                        role={'group'}
                        display={'block'}
                        p={2}
                        rounded={'md'}
                        _hover={{
                          bgColor: '#BBDDE3',
                        }}
                      >
                        <Text
                          transition={'all .3s ease'}
                          _groupHover={{
                            color: '#000',
                          }}
                          fontWeight={400}
                        >
                          {subSubmenu.title}
                        </Text>{' '}
                      </Link>
                    </Stack>
                  );
                })}
              </PopoverContent>
            )} */}
          </Popover>
        </Box>
      </Stack>
    );
  };

  const MobileNav = () => {
    return (
      <Stack
        px={{ base: '5vw', md: '5.25vw', '3xl': '6.25rem' }}
        py={{ base: '2.5vw', md: '1.9vw', '3xl': '2.25rem' }}
        display={{ md: 'none' }}
        position={'absolute'}
        width={'full'}
        bgColor={'#E0E8F3'}
        zIndex={'100000'}
      >
        {mobileitems.map(navItem => (
          <MobileNavItem key={navItem.id} {...navItem} />
        ))}
      </Stack>
    );
  };

  const MobileNavItem = ({ title, href, submenu }) => {
    const { isOpen, onToggle } = useDisclosure();
    return (
      <>
        <Stack spacing={4}>
          <Flex
            py={2}
            as={Link}
            href={href ?? '#'}
            onClick={submenu && onToggle}
            justify={'space-between'}
            align={'center'}
            _hover={{
              textDecoration: 'none',
            }}
          >
            <Text
              fontWeight={'500'}
              color={isOpen ? '#071419' : '#071419'}
              fontSize={{ base: '4.1728vw' }}
              _hover={{ textDecoration: 'none', color: '#' }}
              _active={{ color: '#000' }}
            >
              {title}
            </Text>

            {submenu && (
              <Icon
                color={isOpen ? '#000' : '#8BACB1'}
                as={ChevronDownIcon}
                transition={'all .25s ease-in-out'}
                transform={isOpen ? 'rotate(180deg)' : ''}
                w={5}
                h={5}
              />
            )}
          </Flex>

          <Collapse
            in={isOpen}
            animateOpacity
            style={{ marginTop: '0!important' }}
          >
            <Stack
              mt={2}
              pl={4}
              borderLeft={1}
              borderStyle={'solid'}
              borderColor={useColorModeValue('gray.200', 'gray.700')}
              align={'start'}
            >
              {submenu &&
                submenu.map(submenu => (
                  <MobileSubNav key={submenu.id} {...submenu} />
                ))}
            </Stack>
          </Collapse>
        </Stack>
      </>
    );
  };

  const MobileSubNav = ({ title, href, subSubmenu }) => {
    const { isOpen, onToggle } = useDisclosure();
    return (
      <>
        <Stack w={'full'} spacing={4} onClick={onToggle}>
          <Flex
            py={2}
            justify={'space-between'}
            align={'center'}
            _hover={{
              textDecoration: 'none',
            }}
          >
            <Text
              as={Link}
              href={href ?? '#'}
              fontWeight={500}
              color={isOpen ? '#000' : 'gray'}
              fontSize={{ base: '4.1728vw' }}
              _hover={{ textDecoration: 'none', color: '#000' }}
              _active={{ color: '#000' }}
            >
              {title}
            </Text>
            {subSubmenu && (
              <Icon
                color={isOpen ? '#000' : '#8BACB1'}
                as={ChevronDownIcon}
                transition={'all .25s ease-in-out'}
                transform={isOpen ? 'rotate(180deg)' : ''}
                w={5}
                h={5}
              />
            )}
          </Flex>

          <Collapse
            in={isOpen}
            animateOpacity
            style={{ marginTop: '0!important' }}
          >
            <Stack
              mt={2}
              pl={4}
              borderLeft={1}
              borderStyle={'solid'}
              borderColor={useColorModeValue('gray.200', 'gray.700')}
              align={'start'}
            >
              {subSubmenu &&
                subSubmenu.map(subSubmenu => {
                  return (
                    <Link
                      fontWeight={'400'}
                      color={'#8BACB1'}
                      fontSize={{ base: '4.1728vw' }}
                      _hover={{ textDecoration: 'none', color: '#000' }}
                      _active={{ color: '#000' }}
                      key={subSubmenu.id}
                      py={2}
                      href={subSubmenu.href}
                    >
                      {subSubmenu.title}
                    </Link>
                  );
                })}
            </Stack>
          </Collapse>
        </Stack>
      </>
    );
  };
  const { isOpen, onToggle } = useDisclosure();

  const [language, setLanguage] = useState('En');
  return (
    <Center w={'full'} bg={'#E0E8F3'}>
      <Box fontFamily={'Source Sans Pro'} w={'full'} maxW={'1920px'}>
        <Flex
          color={useColorModeValue('gray.600', 'white')}
          // minH={'60px'}
          px={{ base: '5vw', md: '5.25vw', '3xl': '6.25rem' }}
          py={{ base: '2.5vw', md: '1.9vw', '3xl': '2.25rem' }}
          borderBottom={1}
          borderStyle={'solid'}
          borderColor={useColorModeValue('gray.200', 'gray.900')}
          align={'center'}
          justifyContent={'space-between'}
        >
          <Flex
            alignItems={'center'}
            justifyContent={'space-between'}
            flex={{ base: 1 }}
            justify={{ base: 'start', md: 'start' }}
          >
            <Link href="/">
              <Image
                w={{ base: '29.75vw', lg: '9rem' }}
                src="/navbar/logo.svg"
              />
            </Link>

            <Flex
              display={{ base: 'none', md: 'flex' }}
              justifySelf={'center'}
              ml={10}
            >
              <DesktopNav />
            </Flex>
            <Flex display={{ md: 'flex', base: 'none' }} gap={'1rem'}>
              <Menu>
                <MenuButton
                  borderRadius={'10px'}
                  bgColor={'transparent'}
                  border={'1px solid #354A80'}
                  as={Button}
                  rightIcon={<ChevronDownIcon />}
                >
                  {language}
                </MenuButton>
                <MenuList zIndex={'10000000'}>
                  <MenuItem
                    onClick={() => {
                      setLanguage('En');
                    }}
                  >
                    English
                  </MenuItem>
                  <MenuItem
                    onClick={() => {
                      setLanguage('Ar');
                    }}
                  >
                    Arabic
                  </MenuItem>
                </MenuList>
              </Menu>
              <Link href="/contactus">
                {' '}
                <Button
                  _hover={{
                    bgColor: 'black',
                  }}
                  borderRadius={'10px'}
                  bgColor={'#354A80'}
                  color={'white'}
                  fontSize={'14px'}
                  fontWeight={'500'}
                >
                  Contact Us
                </Button>
              </Link>
            </Flex>
          </Flex>

          <Flex
            flex={{ base: 1, md: 'auto' }}
            ml={{ base: -2 }}
            justifyContent={'flex-end'}
            display={{ base: 'flex', md: 'none' }}
          >
            <IconButton
              onClick={onToggle}
              icon={
                isOpen ? (
                  <CloseIcon
                    _active={{ bg: 'transparent' }}
                    _hover={{ bg: 'transparent' }}
                    color={'#034C5D'}
                    w={3}
                    h={3}
                  />
                ) : (
                  <HamburgerIcon
                    _active={{ bg: 'transparent' }}
                    _hover={{ bg: 'transparent' }}
                    color={'#034C5D'}
                    w={5}
                    h={5}
                  />
                )
              }
              variant={'ghost'}
              aria-label={'Toggle Navigation'}
            />
          </Flex>
        </Flex>

        <Collapse in={isOpen} animateOpacity>
          <MobileNav />
        </Collapse>
      </Box>
    </Center>
  );
};
