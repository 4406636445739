import React from 'react';
import { Box, Center, FormControl } from '@chakra-ui/react';
import Form from './reusable/Formcomp';
import { useRef, useState } from 'react';
import emailjs from '@emailjs/browser';
import { useToast } from '@chakra-ui/react';
import { useNavigate } from 'react-router-dom';

const Inquiry = () => {
  // form validation
  const [fname, setfname] = useState();
  const [email, setemail] = useState();
  const [number, setnumber] = useState();
  const [messages, setmessages] = useState();
  const handleName = e => {
    setfname(e.target.value);
  };
  console.log(fname);
  const handleEmail = e => {
    setemail(e.target.value);
  };
  const handlenumber = e => {
    setnumber(e.target.value);
  };
  const handlemsg = e => {
    setmessages(e.target.value);
  };
  // toast
  const form = useRef();
  const toast = useToast();
  const navigate = useNavigate();

  const sendEmail = e => {
    e.preventDefault();

    if (!fname || !email || !number || !messages) {
      // console.log('error');
      toast({
        title: 'error',
        description: 'fill all input fields',
        status: 'error',
        position: 'top',
        duration: 7000,
        isClosable: true,
      });
    } else {
      emailjs
        .sendForm(
          'service_jxggc25',
          'template_1bpiuwi',
          form.current,
          'eeHXWTnjXvNHD1z8B'
        )
        .then(
          result => {
            console.log(result.text);
            e.target.reset();
            navigate('/thankyou');

            console.log('Message Success');
          },
          error => {
            console.log(error.text);
          }
        );
    }
  };
  return (
    <Center w={'full'}>
      <Box maxW={'1920px'} py={'50px'}>
        <FormControl as={'form'} ref={form} onSubmit={sendEmail}>
          <Form
            heading={'Inquiry Form'}
            description={
              'We welcome any questions or inquiries you may have. Please take a moment to fill out the form below and our team will get back to you promptly.'
            }
            Name={'Full Name'}
            email={'Email'}
            phoneNumber={'Phone Number'}
            comments={'Message or Comments'}
            sideimage={'/forms/query.png'}
            buttonText={'Submit'}
            // for emailjs
            fname={'user_name'}
            mail={'user_email'}
            mobileNumber={'number'}
            commenttext={'text'}
            namevalue={fname}
            handleName={handleName}
            emailvalue={email}
            handleEmail={handleEmail}
            numbervalue={number}
            handlenumber={handlenumber}
            msgvalue={messages}
            handlemsg={handlemsg}
          />
        </FormControl>
      </Box>
    </Center>
  );
};

export default Inquiry;
