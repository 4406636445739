import React from 'react';
import { Box, Heading } from '@chakra-ui/react';
import HeadingPara from '../components/reusable/headingPara';
import { Helmet } from 'react-helmet';

const Privacypolicy = () => {
  return (
    <Box bgColor={'#F4F9FF'} pb={'80px'}>
      <Helmet>
        <title>Privacy Policy | ECIWS</title>
      </Helmet>
      {/* <HeadingPara /> */}
      <HeadingPara />
    </Box>
  );
};

export default Privacypolicy;
