import { Box, ChakraProvider } from '@chakra-ui/react';
import React from 'react';
import { BrowserRouter, Route, Routes } from 'react-router-dom';
import * as ReactDOM from 'react-dom/client';
import App from './pages/App';
import AboutUs from './pages/AboutUs';
import reportWebVitals from './reportWebVitals';
import * as serviceWorker from './serviceWorker';
import { Navbar } from './components/navbar';
import theme from './utilities/theme';
import '../src/styles/index.css';
import ContactUs from './pages/ContactUs';

import ThirdPartyInspection from './pages/ThirdPartyInspection';
import Calibaration from './pages/Calibration';
import Trading from './pages/Trading';
import Training from './pages/Training';
import It from './pages/It';

// font monst
import './styles/fonts/sourcesans/stylesheet.css';
import './styles/fonts/monsterrate/stylesheet.css';

import Blogs from './pages/Blogs';
import Generator from './pages/services/Generator';
import IndustrialAnd from './pages/services/IndustrialAnd';
import Motormaintainace from './pages/services/Motormaintainace';
import PumpRepair from './pages/services/PumpRepair';
import Support from './pages/Support';
import Thankyou from './pages/thankyou';
import Privacypolicy from './pages/Privacypolicy';
import { Footer } from './components/footer';
import ReactGA from 'react-ga4';
import SingleBlogPage from './pages/singleblog/SingleBlogPage';

const container = document.getElementById('root');
const root = ReactDOM.createRoot(container);
ReactGA.initialize('G-M9QQMFQHMT');
root.render(
  <React.StrictMode>
    <ChakraProvider theme={theme}>
      <BrowserRouter>
        <Navbar />
        <Routes>
          {' '}
          <Route path="/" element={<App />}></Route>
          <Route path="/aboutus" element={<AboutUs />}></Route>
          <Route path="/contactus" element={<ContactUs />}></Route>
          <Route path="/blogs" element={<Blogs />}></Route>
          <Route path="/singleblog/:id" element={<SingleBlogPage />}></Route>
          <Route path="/services/generator" element={<Generator />}></Route>
          <Route
            path="/services/industrial"
            element={<IndustrialAnd />}
          ></Route>
          <Route
            path="/services/motormaintain"
            element={<Motormaintainace />}
          ></Route>
          <Route path="/services/pumprepair" element={<PumpRepair />}></Route>
          <Route path="/support" element={<Support />}></Route>
          <Route path="/thankyou" element={<Thankyou />}></Route>
          <Route path="/privacypolicy" element={<Privacypolicy />}></Route>
        </Routes>
        <Footer />
      </BrowserRouter>
    </ChakraProvider>
  </React.StrictMode>
);
const SendAnalytics = () => {
  ReactGA.send({
    hitType: 'pageview',
    page: window.location.pathname,
  });
};

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://cra.link/PWA
serviceWorker.unregister();

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals(SendAnalytics);
