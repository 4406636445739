import { Box, Center, Heading, Image, Text } from '@chakra-ui/react';
import React from 'react';
import { BsArrowRight } from 'react-icons/bs';
import AnimateButton from '../re-components/AnimateButton';
import { RightButton } from '../re-components/rightButton';

export const AboutHero = () => {
  return (
    <Center w={'full'} bg="#F3FDFF">
      <Box
        w="100%"
        maxW="1920px"
        fontFamily="Montserrat"
        display="flex"
        flexDir={{ md: 'row', base: 'column' }}
        justifyContent={{ md: 'space-between', base: 'flex-start' }}
        alignItems={{ base: 'center', xl: 'flex-start' }}
        py={{ lg: '13.5vw', md: '10vw', sm: '15vw', base: '35%' }}
        paddingRight={{ md: '8.85vw', '3xl': '160px' }}
        paddingLeft={{ md: '110px' }}
        // fontFamily={'montserrate'}
      >
        <Box
          alignSelf={'center'}
          justifyContent={'center'}
          margin={{ sm: '64px auto ', md: '0' }}
        >
          <Heading
            margin={{ base: 'auto', md: '0' }}
            maxW={{ md: '40vw', base: '90%', '3xl': '900px' }}
            fontWeight="500"
            fontFamily="Montserrat"
            lineHeight={{ md: '3.64vw', '3xl': '70px' }}
            textAlign={{ base: 'center', md: 'left' }}
            fontSize={{
              lg: '2.2vw',
              '3xl': '50px',
              md: '2vw',
              base: '4.5vw',
            }}
          >
            Excellence in Industrial Services: Your Reliable Maintenance and
            Repair Provider
            <span style={{ color: '#27388E' }}>get certified by us.</span>
          </Heading>
          <Box
            marginTop={{ sm: '16px', base: '16px' }}
            display="flex"
            justifyContent={{ base: 'center', md: 'flex-start' }}
          >
            {/* <RightButton
              icon={
                <BsArrowRight
                  color="#F2F9FB"
                  size={
                    window.innerWidth < 768
                      ? '2.0875vw'
                      : '0.94vw' && window.innerWidth > 1920
                      ? '1.25rem'
                      : '0.94vw'
                  }
                />
              } */}
            <AnimateButton
              // text={'Lets Talk'}
              icon={
                <BsArrowRight
                  color="#F2F9FB"
                  size={
                    window.innerWidth < 768
                      ? '2.0875vw'
                      : '0.94vw' && window.innerWidth > 1920
                      ? '1.25rem'
                      : '0.94vw'
                  }
                />
              }
              bg={'#000938'}
              border={'base'}
              fontColor={'#F2F9FB'}
              text={'Lets Talk'}
              borderRadius={{ base: '1.55vw', md: '10px' }}
              fontSize={{
                '3xl': '20px',
                lg: '1.04vw',
                md: '1.2vw',
                base: '3.75vw',
              }}
              px={{ base: '3.13vw', md: '1vw', lg: '1.6vw   ' }}
              py={{ base: '2.23vw', md: '1.55vw', lg: '0.52vw' }}
              margin={{ md: '0', base: 'auto' }}
              gap={{ md: '0' }}
            />
          </Box>
        </Box>
        <Image
          objectFit={'cover'}
          margin={{ md: '0', sm: '10vw auto 0 auto', base: '64px auto' }}
          borderRadius="12px"
          src="/abouteciws/abouthero.png"
          w={{ md: '30.2vw', base: '58vw', '3xl': '580px' }}
        />
      </Box>
    </Center>
  );
};
