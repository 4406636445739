import React from 'react';
import { Services } from '../../re-components/services';
import { Box } from '@chakra-ui/react';
import { Helmet } from 'react-helmet';

const PumpRepair = () => {
  return (
    <Box bgColor={'#F4F9FF'}>
      <Helmet>
        <title>Services | ECIWS</title>
      </Helmet>{' '}
      <Services
        menu={'SERVICES'}
        submenu={'Pump Repair For All Types'}
        paraExtra={
          "Pumps play a critical role in various industrial processes, and a malfunctioning pump can disrupt operations and lead to costly downtime. Our professional pump repair services are designed to restore efficiency and reliability to all types of pumps, ensuring smooth operation and minimizing disruptions Our skilled technicians specialize in comprehensive pump repair for various types, including centrifugal pumps, positive displacement pumps, and submersible pumps. We conduct thorough diagnostics to identify the root cause of the issue, whether it's a worn-out impeller, a damaged seal, or motor alignment problems. Once the problem is diagnosed, we perform precise repairs using high-quality parts and advanced techniques. Our expertise extends to pump motor repairs, impeller replacement, seal replacement, and realignment, among other crucial repair tasks. We ensure that your pump is restored to its optimal condition for efficient and reliable performance. Our commitment to customer satisfaction means that we work diligently to minimize downtime during the repair process. We understand the importance of a well-functioning pump in your operations and strive to provide efficient and timely repairs. Don't let a malfunctioning pump disrupt your productivity. Trust our experienced technicians for prompt and reliable pump repair. Contact us today to schedule a repair service and experience the benefits of a fully operational pump system."
        }
        image1={'/pumpService/pump.png'}
      />
      <Services
        menu={'PUMP REPAIR'}
        submenu={'Generator Water Pumps'}
        paraExtra={
          "Our Generator Water Pumps service focuses on ensuring the reliable operation and efficient cooling of your generator. Water pumps play a crucial role in maintaining proper temperature levels and preventing overheating, which can lead to equipment failure or reduced performance. During our Generator Water Pumps service, our skilled technicians assess the condition of the water pump and its components. They inspect for any signs of wear, damage, or leaks that may hinder the pump's functionality. If any issues are identified, our technicians promptly address them, whether it involves repairs or complete replacement of the water pump. We source high-quality water pumps from reputable manufacturers to ensure reliability and compatibility with your generator model. During installation, our technicians meticulously fit and align the water pump, ensuring proper connections and efficient water flow. They conduct thorough tests to verify the pump's functionality, including checking for optimal water circulation and pressure."
        }
        paraExtra1={
          "Regular maintenance and inspection of the water pump are essential to prevent unexpected failures and optimize generator performance. Our technicians can provide scheduled maintenance services to ensure the ongoing reliability and efficiency of the water pump. By opting for our Generator Water Pumps service, you can have peace of mind knowing that your generator's cooling system is in optimal condition. We are dedicated to delivering exceptional results and ensuring the reliable operation of your generator. Experience the benefits of our expertise in generator water pumps. Contact us today to discuss your specific needs, and let us provide tailored solutions to enhance the performance and longevity of your generator's cooling system."
        }
        image1={'/pumpService/waterpump.png'}
      />
      <Services
        menu={'PUMP REPAIR'}
        submenu={'Chemical and Acid Pumps'}
        paraExtra={
          "Our Chemical and Acid Pumps service is dedicated to providing reliable and efficient solutions for handling corrosive substances. We understand the unique challenges involved in transporting these liquids safely. That's why our skilled technicians recommend and install high-quality pumps specifically designed to handle corrosive materials, ensuring optimal performance and safety. During our service, our technicians assess your specific requirements and select the most suitable pumps for your application. We prioritize safety during installation, paying close attention to proper alignment and adherence to strict protocols. Our team also offers guidance on maintenance practices to maximize the lifespan and efficiency of the pumps. With our expertise in chemical and acid pumps, you can trust us to deliver durable and safe solutions that ensure smooth and reliable operations. We prioritize the integrity of your pumping systems and provide tailored services to enhance their performance and safety. Safety is our top priority, and our technicians adhere to strict protocols when handling and installing chemical and acid pumps. We take every precaution to prevent leaks, spills, and other potential hazards, ensuring a secure and controlled pumping environment."
        }
        paraExtra1={
          'By choosing our Chemical and Acid Pumps service, you can rely on our expertise to provide durable, efficient, and safe pumping solutions for corrosive substances. We are committed to delivering exceptional results and ensuring the smooth and reliable operation of your chemical and acid pumping systems. Experience the benefits of our expertise in chemical and acid pumps. Contact us today to discuss your specific needs, and let us provide tailored solutions to enhance the performance and safety of your pumping systems.'
        }
        image1={'/pumpService/chemical.png'}
      />
      <Services
        menu={'PUMP REPAIR'}
        submenu={'Submersible Pumps'}
        paraExtra={
          'Our Submersible Pumps service offers efficient and reliable solutions for various applications that require water or liquid transfer below the surface. Submersible pumps are designed to operate submerged in fluids, making them ideal for tasks such as drainage, sewage management, well pumping, and irrigation. Our skilled technicians understand the unique requirements and challenges associated with submersible pumps. We offer a wide range of high-quality pumps designed for durability, efficiency, and optimal performance in submerged conditions. During our Submersible Pumps service, our technicians assess your specific needs and recommend the most suitable pump options for your application. We ensure proper installation and alignment of the pumps, taking into account factors such as flow rates, pressure requirements, and the depth of submersion. We prioritize safety and reliability during installation, adhering to strict protocols to prevent leaks and ensure efficient water or liquid transfer. Our team also provides guidance on pump maintenance to maximize their lifespan and efficiency, allowing you to achieve long-term cost savings.'
        }
        paraExtra1={
          'By choosing our Submersible Pumps service, you can rely on our expertise to deliver efficient, reliable, and durable pumping solutions. We are committed to meeting your unique requirements and providing tailored solutions that optimize the performance of your submersible pumping systems. Experience the benefits of our expertise in submersible pumps. Contact us today to discuss your specific needs, and let us provide the right solutions for your water or liquid transfer requirements, below the surface.'
        }
        image1={'/pumpService/submersible.png'}
      />
    </Box>
  );
};

export default PumpRepair;
