import {
  Box,
  Center,
  Flex,
  Heading,
  Image,
  Link,
  Text,
} from '@chakra-ui/react';
import { motion } from 'framer-motion';
import React from 'react';

const ServicesArr = [
  {
    id: 0,
    image: 'serviceseciws/csimg11.svg',
    title: 'Generator maintenance',
    discription1: 'Electrical and mechanical troubleshooting',
    discription2: 'Voltage adjustment',
    discription3: 'Alternator and Exciter Rewinding',
    discription4:
      'Supply and installation of all mechanical and electrical spares like AVR, Diodes, control panel, fuel Pump',
    arrowimg: './serviceseciws/arrow-right.svg',
    href: './services/Generator',
  },
  {
    id: 1,
    image: 'serviceseciws/csimg2.svg',
    title: 'Motor Maintenance',
    discription1: 'Motor Maintenance up to 500 KW',
    discription2: 'Motor rewinding',
    discription3: 'Bearings replacement',
    discription4: 'Rotor balancing',
    discription5: 'Machining of body and covers',
    arrowimg: '/serviceseciws/arrow-right.svg',
    href: 'calibration',
  },
  {
    id: 2,
    image: 'serviceseciws/csimg3.svg',
    title: 'pump repair for all types',
    discription1: 'Generator water pumps',
    discription2: 'Chemical and acid pumps',
    discription3: 'Submersible pumps',
    arrowimg: './serviceseciws/arrow-right.svg',
    href: './services/pumprepair',
  },
  {
    id: 3,
    image: 'serviceseciws/csimg4.svg',
    title: 'Industrial and medical compressors maintenance',
    discription1: 'Electrical and mechanical troubleshooting',
    discription2: 'Supply and installation of all spare parts',
    arrowimg: './serviceseciws/arrow-right.svg',
    href: './services/industrial',
  },
];
const ServicesArr2 = [];

const CoreServices = () => {
  const parentVarient = {
    inital: {
      color: 'inherit',
      backgroundColor: 'inherit',
    },
    animate: {
      color: 'white',
      backgroundColor: '#000938',
    },
  };
  const childVarient = {
    inital: {
      color: '#034C5E',
      marginTop: '100px',
      backgroundColor: 'inherit',
    },
    animate: {
      color: 'white',

      //   backgroundColor: "#0FB6A8",
    },
  };
  const discriptionVarient = {
    inital: {
      color: '#464E77',
    },
    animate: {
      color: 'white',
    },
  };
  const childBoxVarient = {
    inital: {
      // color: "#034C5E",
      // backgroundColor: "inherit",
      backgroundColor: '#ffffff',
      // marginTop: '100px',
    },
    animate: {
      backgroundColor: '#000938',
    },
  };
  const arrowVarient = {
    inital: {
      // backgroundColor :"#81DDD6",
      marginTop: '100px',
    },
    animate: {
      // backgroundColor :"white",
      color: 'white',
      filter: 'brightness(0) invert(1)',
    },
  };

  return (
    // for desktop view
    <Center w={'full'} bg="#f4fffe">
      <Box
        w="100%"
        maxW="1920px"
        bg="#f4fffe"
        paddingBottom={{ lg: '100px', base: '24px' }}
      >
        <Heading
          textAlign="center"
          overflowX="hidden"
          paddingTop={{ lg: '4rem', base: '2rem' }}
          fontFamily={'Montserrat'}
          // fontFamily="sans-serif"
          color="#000938"
          fontSize={{ lg: '2.291vw', md: '2.6vw', base: '5vw' }}
        >
          Core Services
        </Heading>

        <Flex
          flexWrap="wrap"
          // flexGrow={'wrap'}
          // flexDir={{ md: 'row', base: 'column' }}
          margin={{ md: '3.3375vw auto', base: '2rem auto' }}
          justifyContent="center"
          alignContent="center"
          w="100%"
          maxW="1920px"
          // gap="3.33vw"
          gap={{ base: '4.7vw', '3xl': '50px' }}
        >
          {ServicesArr.map((item, index) => {
            return (
              <Link href={item.href} _hover={{ textDecoration: 'none' }}>
                <Box
                  as={motion.div}
                  variants={parentVarient}
                  initial="initial"
                  whileHover="animate"
                  border="1px solid #C0DDFF"
                  boxShadow={{
                    lg: 'rgba(17, 12, 46, 0.15) 0px 48px 100px 0px',
                  }}
                  padding={{
                    '3xl': '40px',
                    lg: '2.7vw 2.7vw 2.18vw 2.18vw ',
                    base: '4.6vw',
                  }}
                  borderRadius="30px"
                  margin={{ md: '0', base: 'auto' }}
                  w={{
                    lg: '26.5vw',
                    md: '40vw',
                    base: '80vw',
                    '3xl': '508.8px',
                  }}
                  h={{ md: '30.7vw', base: '100vw', '3xl': '589.44px' }}
                  minH={{
                    base: '300px',
                    md: '450px',
                    lg: '350px',
                    xl: '550px',
                  }}
                  key={index}
                  diplay="flex"
                  flexDir="column"
                  position="relative"
                  cursor="pointer"
                >
                  <Box
                    as={motion.div}
                    variants={childBoxVarient}
                    bg="#ffffff"
                    w={{ lg: '60px', base: '40px' }}
                    h={{ lg: '60px', base: '40px' }}
                    px="4px"
                    // marginTop={{ lg: '15px', base: '2' }}
                    py={{ lg: '2px', base: '' }}
                    borderRadius="10px"
                    position="relative"
                  >
                    <Image
                      // position="absolute"
                      // margin="auto"
                      src={item.image}
                      // top={{ lg: '2', base: '2' }}
                      // left={{ lg: '1', base: '2 ' }}
                      // boxSize={{ lg: '40px', base: '25px' }}
                    />
                  </Box>
                  <Text
                    as={motion.div}
                    variants={childVarient}
                    marginTop={{ lg: '2.6vw', base: '15px', '3xl': '56px' }}
                    // py={{ lg: '1.04vw', '3xl':'0', base: '0' }}
                    // initial="initial"
                    // whileHover="animate"
                    textTransform="uppercase "
                    fontSize={{
                      '3xl': '34px',
                      lg: '1.5vw',
                      md: '2.77vw',
                      base: '4.0625vw',
                    }}
                    fontFamily={'Montserrat'}
                    color="#000938"
                    fontWeight="600"
                    marginBottom={{ lg: '0', base: '16px', xl: '1rem' }}
                    w={{ '3xl': '90%', lg: '100%', base: '100%' }}
                    // maxW={{md:'auto', base:'190px'}}
                    // minH={{ lg: '7.68vw', md: '8.3vw' }}
                  >
                    {item.title}
                  </Text>
                  <Text
                    as={motion.div}
                    variants={discriptionVarient}
                    // initial="initial"
                    fontFamily="Source Sans Pro"
                    fontWeight="400"
                    letterSpacing="1px"
                    lineHeight={{
                      lg: '1.56vw',
                      md: '20px',
                      '3xl': '30px',
                      base: '6vw',
                    }}
                    fontSize={{
                      lg: '0.989vw',
                      '3xl': '20px',
                      md: '15px',
                      base: '3.84vw',
                    }}
                    // color="#464E77"
                    // paddingBottom={pb}
                  >
                    {/* {item.discription} */}
                    <ul style={{ padding: '0 10px' }}>
                      {item.discription1 && <li>{item.discription1}</li>}
                      {item.discription2 && <li>{item.discription2}</li>}
                      {item.discription3 && <li>{item.discription3}</li>}
                      {item.discription4 && <li>{item.discription4}</li>}
                      {item.discription5 && <li>{item.discription5}</li>}
                    </ul>
                  </Text>
                  <Image
                    w={{
                      '3xl': '64px',
                      md: '3.85vw',
                      lg: '3.385vw',
                      sm: '10vw',
                      base: '24px',
                    }}
                    as={motion.img}
                    variants={arrowVarient}
                    position="absolute"
                    bottom={{ md: '2.039vw', base: '5' }}
                    right="10"
                    color="white"
                    src={item.arrowimg}
                  />
                </Box>
              </Link>
            );
          })}
        </Flex>
      </Box>
    </Center>
  );
};

export default CoreServices;
