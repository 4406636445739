import { Box, Button, Flex, Link, Text, transition } from '@chakra-ui/react';
import { motion } from 'framer-motion';
import React, { useState } from 'react';
import { BsArrowRight, BsArrowUpRight } from 'react-icons/bs';
// import './AnimateButton.css';

const ArrowUpBtn = ({
  text,
  icon,
  bg,
  border,
  arrowColor,
  fontColor,
  radius,
}) => {
  const [isHover, setIsHover] = useState(false);
  // const textArr = ['I','n','s','p','e','c','t' ,'N','o','w'];
  const textArr1 = [text];
  const textArr = textArr1.splice('');

  const alphaUpVariant = {
    initial: {
      opacity: 1,

      y: 0,
      transition: {
        duration: 0.8,
        type: 'tween',
        ease: [0.44, 0, 0, 1],
      },
    },
    animate: {
      opacity: 0,
      y: '-10px',

      transition: {
        duration: 0.5,
        type: 'tween',
        ease: [0.44, 0, 0, 1],
      },
    },
  };
  const alphaDownVariant = {
    initial: {
      opacity: 0,
      y: '10px',
      transition: {
        duration: 0.5,
        type: 'tween',
        // ease: [0.25, 1, 0.5, 1],
        ease: [0.44, 0, 0, 1],
      },
    },
    animate: {
      opacity: 1,
      y: 0,
      transition: {
        delay: 0.05,
        duration: 0.5,
        type: 'tween',
        ease: [0.44, 0, 0, 1],
      },
    },
  };
  console.log(textArr);

  return (
    <Link href="/contactus" _hover={{ textDecoration: 'none', bg: '#F2F9FB' }}>
      <Button
        // Hello
        as={motion.button}
        onMouseEnter={() => setIsHover(true)}
        onMouseLeave={() => setIsHover(false)}
        _hover={{ bg: { bg } }}
        height={{ base: '10.74vw', md: '3.125vw', '3xl': '3.75rem' }}
        color={fontColor}
        lineHeight={'17px'}
        // bgColor={bg}
        bg=" #354A80"
        fontSize={{ base: '3.625vw', md: '0.79vw', '3xl': '0.94rem' }}
        borderRadius={radius}
        // fontWeight={'400'}
        border={border}
        py={{ base: 'min(4.065vw,15px)', md: '1.2vw', '3xl': '1.625rem' }}
        px={{ base: 'min(4.44vw,20px)', md: '3.25vw', '3xl': '3.25rem' }}
        display="flex"
        fontWeight="600"
        gap="0"
      >
        {console.log(isHover)}

        {textArr.map((item, index) => {
          console.log(item);
          return (
            <Flex key={index} fontFamily="sans-serif">
              <Text
                as={motion.p}
                key={index}
                variants={alphaUpVariant}
                animate={isHover ? 'animate' : 'initial'}
              >
                {item}
              </Text>
              <Text
                as={motion.p}
                key={index}
                variants={alphaDownVariant}
                // initial="initial"
                animate={isHover ? 'animate' : 'initial'}
                position="absolute"
              >
                {item}
              </Text>
            </Flex>
          );
        })}
        <Box
          as={motion.p}
          animate={
            isHover
              ? {
                  x: '20px',
                  y: '-10px',
                  opacity: [1, 0],
                  transition: {
                    duration: 0.5,
                    ease: [0.25, 1, 0.5, 1],
                    type: 'tween',
                    // delay: 0.1,
                  },
                }
              : {
                  x: '10px',
                  y: '0',
                  transition: {
                    ease: [0.25, 1, 0.5, 1],
                  },
                }
          }
        >
          <BsArrowUpRight />
        </Box>
        <Box
          as={motion.p}
          position="absolute"
          animate={
            isHover
              ? {
                  opacity: [0, 1],

                  right: '30px',
                  y: '0',
                  transition: {
                    delay: 0.05,
                    duration: 0.5,
                    ease: [0.25, 1, 0.5, 1],
                    type: 'tween',
                  },
                }
              : {
                  right: '30px',
                  y: '10px',
                  delay: 0.2,
                  opacity: 0,
                  transition: {
                    duration: 0.2,
                    ease: [0.25, 1, 0.5, 1],
                    type: 'tween',
                  },
                }
          }
        >
          <BsArrowUpRight />
        </Box>
      </Button>
    </Link>
  );
};

export default ArrowUpBtn;
