import { Box, Center, Flex, Image, Text } from '@chakra-ui/react';
import React from 'react';

export const MachinesTested = () => {
  return (
    <Center w={'full'} bgColor={'#000938'}>
      <Box
        w={'full'}
        bgColor={'#000938'}
        maxW={'1920px'}
        display={'flex'}
        flexDirection={'column'}
        gap={{ base: '12.815vw', md: '3.7vw', '3xl': '5.8125rem' }}
        px={{ base: '6.25vw', md: '0' }}
        pt={{ base: '14.065vw', md: '8.025vw', '3xl': '9.625rem' }}
        pl={{ md: '5.5vw', '3xl': '6.625rem' }}
        pb={{ base: '14.065vw', md: '11.25vw', '3xl': '13.5625rem' }}
        alignItems={'start'}
      >
        <Text
          pr={{ md: '32.5vw', '3xl': '39rem' }}
          fontSize={{ base: '4vw', md: '1.565vw', '3xl': '1.875rem' }}
          fontWeight={'600'}
          fontFamily={'Montserrat'}
          color={'#FFF'}
          lineHeight={{ base: '7.934vw', md: '3.1vw', '3xl': '3.7rem' }}
        >
          2220+ machines repaired in 2019, inspected by trained engineers in the
          country. Without compromising on quality.
        </Text>
        <Center w={'full'}>
          <Box
            w={'90%'}
            display={'flex'}
            flexDir={{ base: 'column', md: 'column' }}
            gap={{ base: '6.25vw', md: '5.104vw', '3xl': '6.125rem' }}
            // px={{ base: '7.5vw', md: '0' }}
          >
            <Flex gap={{ base: '6.25vw', md: '5.104vw', '3xl': '6.125rem' }}>
              <Image
                w={{ base: 'min(15vw,75px)', md: 'min(9.7vw,187px)' }}
                // h={{ base: '6.13125vw', md: '2.135vw', '3xl': '2.563rem' }}
                src="/eciws/busch.svg"
              />
              <Image
                w={{ base: 'min(15vw,75px)', md: 'min(9.7vw,187px)' }}
                // h={{ base: '6.13125vw', md: '2.135vw', '3xl': '2.563rem' }}
                src="/eciws/mono.svg"
              />
              <Image
                w={{ base: 'min(15vw,75px)', md: 'min(9.7vw,187px)' }}
                // h={{ base: '6.13125vw', md: '2.135vw', '3xl': '2.563rem' }}
                src="/eciws/compair.svg"
              />
              <Image
                w={{ base: 'min(15vw,75px)', md: 'min(9.7vw,187px)' }}
                // h={{ base: '6.13125vw', md: '2.135vw', '3xl': '2.563rem' }}
                src="/eciws/sullair.svg"
              />
            </Flex>
            <Flex gap={{ base: '6.25vw', md: '5.104vw', '3xl': '6.125rem' }}>
              <Image
                w={{ base: 'min(15vw,75px)', md: 'min(9.7vw,187px)' }}
                // h={{ base: '6.13125vw', md: '2.135vw', '3xl': '2.563rem' }}
                src="/eciws/atlas.svg"
              />
              <Image
                w={{ base: 'min(15vw,75px)', md: 'min(9.7vw,187px)' }}
                // h={{ base: '6.13125vw', md: '2.135vw', '3xl': '2.563rem' }}
                src="/eciws/perkins.svg"
              />
              <Image
                w={{ base: 'min(15vw,75px)', md: 'min(9.7vw,187px)' }}
                // h={{ base: '6.13125vw', md: '2.135vw', '3xl': '2.563rem' }}
                src="/eciws/cat.svg"
              />
              <Image
                w={{ base: 'min(15vw,75px)', md: 'min(9.7vw,187px)' }}
                // h={{ base: '6.13125vw', md: '2.135vw', '3xl': '2.563rem' }}
                src="/eciws/ksb.svg"
              />
            </Flex>
          </Box>
        </Center>
      </Box>
    </Center>
  );
};
