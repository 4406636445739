import { Box, Button, Flex, Image, Text } from '@chakra-ui/react';
import { Link } from '@chakra-ui/react';
import React from 'react';
// import Testimonialcontent from "./Testimonialcontent";
export default function TestimonialsComponent({
  tittle,
  hello,
  img,
  h,
  w,
  pr,
  pb,
  topradius,
  bottomradius,
}) {
  return (
    <Box
      // bgGradient={'linear(#DEECEF,#EDF7F9)'}
      h={h}
      w={w}
      // border="1px solid #C9DDF5"
      // border={'1px solid #C9DDF5'}
      borderRadius={'5px'}
      boxSizing={'border-box'}
      borderTopLeftRadius={topradius}
      borderBottomLeftRadius={bottomradius}
    >
      <Flex
        flexDir={'column'}
        gap={{ md: '1.0416vw', '3xl': '1.25rem' }}
        pl={{ '3xl': '35px', lg: '2.18vw', md: '1.822vw' }}
        pt={{ '3xl': '35px', lg: '2.18vw', md: '1.822vw' }}
        pr={pr}
        pb={pb}
        fontFamily={'Montserrat'}
        bg={'white'}
        borderRadius={'25px'}
        border={'1px solid #C9DDF5'}
        // borderTopLeftRadius={topradius}
        // borderBottomLeftRadius={bottomradius}
      >
        <Box
          bgColor={'#CFD0E8'}
          width={{ '3xl': '100px', md: '4.108vw' }}
          height={{ '3xl': '100px', md: '4.108vw' }}
          alignItems={'center'}
          display={'flex'}
          justifyContent={'center'}
          borderRadius={'50%'}
        >
          <Image src={img} width={{ '3xl': '56px', md: '2.116vw' }} />
        </Box>
        <Text
          fontWeight={'600'}
          fontSize={{ '3xl': '42px', md: '2.10vw' }}
          lineHeight={{ '3xl': '52px', md: '2.70vw' }}
          color={'#000938'}
        >
          {tittle}
        </Text>
        <Text
          pt={{ md: '0.834vw', '3xl': '1rem' }}
          fontWeight={'500'}
          fontSize={{ '3xl': '20px', md: '1.041vw' }}
          lineHeight={{ '3xl': '37px', md: '1.927vw' }}
          color={'#464E77'}
          letterSpacing={'2%'}
          maxW={'50ch'}
        >
          {hello}
        </Text>
      </Flex>
    </Box>
  );
}
