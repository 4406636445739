import { Box, Center, Flex, Text } from '@chakra-ui/react';
import React from 'react';
import MobileOfferComponent from './MobileOfferComponent';

export function MobileWhatWeOffer() {
  return (
    <Box
      fontFamily={'Montserrat'}
      display={{ base: 'flex', md: 'none' }}
      flexDir={'column'}
      bg={'#F4F9FF'}
    >
      <Center display={'flex'} flexDir={'column'}>
        <Flex
          flexDir={'column'}
          gap={{ base: '13px', sm: '4.0625vw' }}
          paddingBottom={{ base: '40px', sm: '12.5vw' }}
          pt={{ base: '64px', sm: '20vw' }}
        >
          <Text
            fontWeight={'600'}
            fontSize={{ base: '22.7px', sm: '7.093vw' }}
            lineHeight={'52px'}
            color={'#000938'}
            fontFamily={'Montserrat'}
          >
            What we offer
          </Text>
          <Text
            fontWeight={'600'}
            fontSize={{ base: '13px', sm: '4vw' }}
            lineHeight={'17px'}
            letterSpacing={'3%'}
            color={'#000938'}
            fontFamily={'Montserrat'}
          >
            A Total Solution To All Your Inspection Needs
          </Text>
        </Flex>

        <Flex flexDir={'column'} gap={{ base: '25.07px', sm: '7.83vw' }}>
          <Box>
            <MobileOfferComponent
              tittle={'Our Objective'}
              information={
                'Our primary objective to our client is ensuring prompt job execution.'
              }
              img={'/whatweoffer/Subtract.png'}
              w={{ base: '250px', sm: '80vw' }}
              maxW={'450px'}
              pb={{ base: '36px', sm: '11.25vw' }}
              pr={{ base: '12px', sm: '3.98vw' }}
            />
          </Box>
          <Box marginLeft={{ base: '38px', sm: '11.875vw' }}>
            <MobileOfferComponent
              tittle={'Our Standards'}
              information={
                'High quality standards in accordance to PDO and Non-PDO specifications, BS, EN, ASME, ISO.'
              }
              img={'/whatweoffer/Union.png'}
              w={{ base: '250px', sm: '80vw' }}
              pb={{ base: '36px', sm: '11.25vw' }}
              pr={{ base: '12px', sm: '3.75vw' }}
            />
          </Box>
          <Box>
            <MobileOfferComponent
              tittle={'Our Promise'}
              information={
                'Guarantee high level of quality for every service execution.'
              }
              img={'/whatweoffer/new.png'}
              w={{ base: '250px', sm: '80vw' }}
              h={{ base: '183px', sm: '210px' }}
              pb={{ base: '45px', sm: '14.0625vw' }}
              pr={{ base: '12.5px', sm: '3.906vw' }}
            />
          </Box>
          <Box marginLeft={{ base: '38px', sm: '11.87vw' }}>
            <MobileOfferComponent
              tittle={'Our Motto'}
              information={'Utter commitment in all and any work we undertake.'}
              img={'/whatweoffer/motto.png'}
              w={{ base: '236px', sm: '80vw' }}
              pb={{ base: '45px', sm: '14.0625vw' }}
              pr={{ base: '12px', sm: '3.75vw' }}
            />
          </Box>
          <Box>
            <MobileOfferComponent
              tittle={'Our Pricing'}
              information={
                'Affordable and uniform prices across all our services to all our clients.'
              }
              img={'/whatweoffer/dollar.png'}
              w={{ base: '250px', sm: '80vw' }}
              h={{ base: '183px', sm: '210px' }}
              pb={{ base: '36px', sm: '11.25vw' }}
              pr={{ base: '12px', sm: '3.75vw' }}
            />
          </Box>
          <Box>
            <MobileOfferComponent
              tittle={'Our Approvals & Accreditations'}
              information={
                'Approvals and accreditation from LEEA, PDO, JSRS, OPAL, SGS.'
              }
              img={'/whatweoffer/like.png'}
              w={{ base: '275px', sm: '80vw' }}
              h={{ base: '183px', sm: '210px' }}
              pb={{ base: '45px', sm: '14.0625vw' }}
              pr={{ base: '12px', sm: '3.75vw' }}
            />
          </Box>
        </Flex>
        <Box
          fontSize={{ base: '12px', sm: '3.75vw' }}
          textAlign={'center'}
          maxW={'41ch'}
          fontWeight={'500'}
          lineHeight={{ base: '17px', sm: '5.31vw' }}
          letterSpacing={'0.03em'}
          pt={{ base: '24px', sm: '7.5vw' }}
          pb={'5vw'}
        >
          <Text
            color={'#1FAFA4'}
            fontFamily={'Montserrat'}
            paddingBottom={'70px'}
          >
            So what are you waiting for ?
            <Text as={'span'} color={'#49848B'} fontFamily={'Montserrat'}>
              {' '}
              Avail our service for inspection and load test certificate today!
            </Text>
          </Text>
        </Box>
      </Center>
    </Box>
  );
}
