import React, { useEffect, useState } from 'react';
import { Box, Center, Image, Text } from '@chakra-ui/react';
import { useParams } from 'react-router-dom';
import { blogsList } from '../../config/blogsData';
import { Helmet } from 'react-helmet';

const SingleBlogPage = () => {
  const { id } = useParams();
  const [blog, setBlog] = useState();

  useEffect(() => {
    let blog = blogsList.find(blog => blog.id === parseInt(id));

    if (blog) {
      setBlog(blog);
    }
  }, [id]);
  return (
    <div>
      <Helmet>
        <title>Blogs | ECIWS</title>
      </Helmet>
      {blog && (
        <Center bgColor={'#F4F9FF'} w={'full'}>
          <Box
            w={{ lg: '90%' }}
            display={'flex'}
            flexDirection={'column'}
            gap={'1.5rem'}
            py={'3rem'}
            maxW={'1920px'}
          >
            <Box display={{ lg: 'grid' }} gridTemplateColumns={'0.8fr 1fr'}>
              {/* image title grid */}
              <Box
                display={'flex'}
                flexDir={'column'}
                gridColumnStart={'1'}
                gridColumnEnd={'2'}
                mr={{ lg: '5rem' }}
                alignItems={{ lg: 'start', base: 'center' }}
              >
                <Text
                  textAlign={{ base: 'center', lg: 'start' }}
                  fontFamily={'Montserrat'}
                  fontSize={{ base: '1.5rem', lg: 'min(2vw,2rem)' }}
                  fontWeight={'600'}
                >
                  {blog.title}
                </Text>
                <Text py={'1rem'} fontSize={{ lg: 'min(1.2vw,1.2rem)' }}>
                  {blog.datenauth}
                </Text>
                <Image
                  rounded={'lg'}
                  h={{ lg: '25vw', base: 'full' }}
                  w={{ lg: '35vw', base: 'full' }}
                  objectFit={'cover'}
                  src={blog.image}
                />
              </Box>

              {/* second side for content */}
              <Text
                px={'1rem'}
                my={'2rem'}
                color={'#464E77'}
                w={'full'}
                fontSize={{ lg: 'min(1.1vw,1.2rem)' }}
                textAlign={{ lg: 'start', base: 'center' }}
                display={'grid'}
                gridColumnStart={'2'}
                gridColumnEnd={'3'}
              >
                {blog.description
                  .filter((para, index) => index < 4)
                  .map((para, index) => (
                    <div>
                      {para} <br />
                      <br />
                    </div>
                  ))}
              </Text>
            </Box>
            <Text
              fontSize={{ lg: 'min(1.1vw,1.2rem)' }}
              textAlign={{ lg: 'start', base: 'center' }}
              px={'1rem'}
              color={'#464E77'}
            >
              {blog.description
                .filter((para, index) => index >= 4)
                .map((para, index) => (
                  <div>
                    {para} <br />
                    <br />
                  </div>
                ))}
            </Text>
          </Box>
        </Center>
      )}
    </div>
  );
};

export default SingleBlogPage;
