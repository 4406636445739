import { Box } from '@chakra-ui/react';
import React from 'react';

const Titledescr = ({ headline, description }) => {
  return (
    <Box display={'flex'} flexDir={'column'} gap={{ base: '1rem', lg: '1rem' }}>
      <Box
        color={'#000938'}
        fontSize={{ lg: '32px', base: '22px' }}
        fontWeight={500}
      >
        {headline}
      </Box>

      <Box
        color={'#464E77'}
        fontSize={{ lg: '18px', base: '16px' }}
        fontWeight={400}
        lineHeight={{ base: '1.5rem', lg: '24px' }}
      >
        {description}
      </Box>
    </Box>
  );
};

export default Titledescr;
