import React from 'react';
import { Services } from '../../re-components/services';
import { Box } from '@chakra-ui/react';
import { Helmet } from 'react-helmet';

const IndustrialAnd = () => {
  return (
    <Box bgColor={'#F4F9FF'}>
      <Helmet>
        <title>Services | ECIWS</title>
      </Helmet>{' '}
      <Services
        menu={'SERVICES'}
        submenu={'Industrial & Medical Compressors Maintenance'}
        paraExtra={
          'Compressors are vital components in both industrial and medical settings, providing the necessary air pressure for various applications. Regular maintenance is crucial to ensure the reliability and optimal performance of these compressors. Our professional maintenance services are tailored for industrial and medical compressors, helping you maintain uninterrupted operations. Our skilled technicians specialize in comprehensive compressor maintenance for industrial and medical applications. We conduct thorough inspections, checking for air leaks, worn-out components, and abnormal vibrations. We also perform necessary lubrication, filter replacements, and belt adjustments to keep your compressors running efficiently. For medical compressors, we adhere to strict compliance standards to ensure the safety and integrity of the air supply in medical environments. We provide meticulous maintenance, including regular filter changes, sanitization, and calibration, to meet the stringent requirements of medical facilities. By investing in routine maintenance, you can extend the lifespan of your compressors, reduce the risk of unexpected failures, and optimize energy efficiency. Our experts can assist you in creating a personalized maintenance schedule based on your specific needs and compliance requirements. Ensure the reliability and performance of your industrial and medical compressors with our professional maintenance services. Contact us today to schedule a maintenance visit and experience the peace of mind that comes with well-maintained compressors.'
        }
        image1={'/industrialService/industrial.png'}
      />
      <Services
        menu={'INDUSTRIAL & MEDICAL COMPRESSORS MAINTENANCE'}
        submenu={'Electrical and Mechanical Troubleshooting'}
        paraExtra={
          "Our Electrical and Mechanical Troubleshooting service offers comprehensive solutions for identifying and resolving issues related to both electrical and mechanical systems. With our expertise in troubleshooting, we aim to diagnose and rectify problems swiftly and effectively. During our service, our skilled technicians utilize advanced diagnostic tools and techniques to identify electrical and mechanical faults accurately. They meticulously analyze electrical systems, such as wiring, control panels, and components, to detect any malfunctions, wiring issues, or irregularities that may impact performance. Simultaneously, our technicians conduct detailed inspections of mechanical components, including engines, gears, belts, and bearings, among others. By carefully examining these parts, they identify any wear and tear, misalignments, or anomalies that may cause performance issues or equipment failure. Once the issues are identified, our technicians provide expert solutions to resolve them promptly. This may involve repairing or replacing faulty components, adjusting settings, or conducting necessary maintenance procedures. We prioritize efficiency and safety, ensuring that all troubleshooting measures adhere to industry standards and regulations. With our Electrical and Mechanical Troubleshooting service, you can rely on our team to optimize the performance and reliability of your systems. Whether it's a minor electrical issue or a complex mechanical malfunction, we approach every problem with precision and dedication. Experience the benefits of our expertise in electrical and mechanical troubleshooting. Contact us today to discuss your specific needs, and let us provide tailored solutions to ensure the smooth operation of your equipment and systems."
        }
        image1={'/industrialService/electrical.png'}
      />
      <Services
        menu={'INDUSTRIAL & MEDICAL COMPRESSORS MAINTENANCE'}
        submenu={'Supply and Installation of all Spare Parts'}
        paraExtra={
          'Our Supply and Installation of all Spare Parts service offers a comprehensive solution for sourcing and installing the necessary components to keep your industrial machinery running smoothly. We understand the importance of reliable spare parts in maintaining the performance and longevity of your equipment. During our service, our dedicated team ensures the efficient procurement of high-quality spare parts from reputable manufacturers. We have established strong relationships with trusted suppliers to ensure the availability of a wide range of components for various machinery types and brands. Our technicians carefully assess your specific requirements and recommend the most suitable spare parts for your equipment. We prioritize sourcing genuine parts to ensure compatibility and optimal performance. Once the necessary spare parts are acquired, our skilled technicians handle the installation process with precision and expertise. They follow industry best practices, ensuring proper fit, alignment, and functionality. Our team understands the criticality of accurate installation in maximizing the performance and lifespan of your machinery.'
        }
        paraExtra1={
          ' By choosing our Supply and Installation of all Spare Parts service, you can have peace of mind knowing that your equipment is equipped with reliable components. We are committed to delivering exceptional results and ensuring the seamless operation of your industrial machinery. Experience the benefits of our expertise in spare parts supply and installation. Contact us today to discuss your specific needs, and let us provide tailored solutions to enhance the performance and reliability of your equipment.'
        }
        image1={'/industrialService/supply.png'}
      />
    </Box>
  );
};

export default IndustrialAnd;
