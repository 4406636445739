import React from 'react';
import { Services } from '../../re-components/services';
import { Box } from '@chakra-ui/react';
import { Helmet } from 'react-helmet';

const Motormaintainace = () => {
  return (
    <Box bgColor={'#F4F9FF'}>
      <Helmet>
        <title>Services | ECIWS</title>
      </Helmet>{' '}
      <Services
        menu={'SERVICES'}
        submenu={'Motor Maintenance'}
        paraExtra={
          'Motors are the workhorses of industrial machinery, and proper maintenance is essential for their optimal performance and longevity. Our professional motor maintenance services are designed to ensure smooth operations, minimize downtime, and extend the lifespan of your motors. Our experienced technicians specialize in comprehensive motor maintenance. We conduct regular inspections to identify potential issues such as worn-out bearings, misaligned components, and excessive heat buildup. We also check for proper lubrication and perform necessary adjustments to optimize motor performance. In addition to inspections, we offer a range of maintenance services including cleaning, testing, and component replacements. We use advanced diagnostic tools to assess motor health, measure voltage and current levels, and analyze vibration patterns to detect any abnormalities. By addressing potential problems proactively, we help prevent costly breakdowns and maximize motor efficiency. Our customized maintenance plans are tailored to your specific needs and equipment requirements. Whether you have AC or DC motors, single-phase or three-phase systems, we have the expertise to ensure optimal performance. Investing in regular motor maintenance not only improves reliability but also enhances energy efficiency, reducing operational costs in the long run. Trust our experts to keep your motors running smoothly. Contact us today to schedule a motor maintenance visit and experience the benefits of proactive care.'
        }
        image1={'/motorService/motor.png'}
      />
      <Services
        menu={'MOTOR MAINTENANCE'}
        submenu={'Motor Maintenance upto 500 KW'}
        paraExtra={
          'Our Motor Maintenance up to 500 KW service is designed to cater to the maintenance needs of motors with power capacities of up to 500 KW. We possess the expertise and resources to ensure the optimal performance and longevity of your motors. Regular maintenance is essential to keep motors operating at their peak efficiency and prevent costly breakdowns. Our skilled technicians have extensive knowledge in motor maintenance and employ industry-leading practices to deliver exceptional results. During motor maintenance, our technicians conduct thorough inspections to identify any potential issues or signs of wear and tear. They meticulously clean motor components, inspect wiring and connections, and lubricate moving parts as necessary. Additionally, they perform tests to assess motor performance, including voltage checks, current analysis, and vibration monitoring. If any faults or abnormalities are detected, our technicians promptly address them, replacing worn-out parts, adjusting settings, or conducting necessary repairs. We ensure that motors are properly aligned and balanced to prevent undue stress and maximize their operational efficiency.'
        }
        paraExtra1={
          'By investing in our Motor Maintenance service, you can minimize downtime, extend the lifespan of your motors, and optimize energy efficiency. Our commitment to delivering high-quality maintenance solutions ensures that your motors operate reliably and efficiently, meeting your production demands. Experience the benefits of our Motor Maintenance service by entrusting your motors to our skilled technicians. Contact us today to discuss your requirements and let us provide tailored solutions to enhance the performance and reliability of your motors up to 500 KW.'
        }
        image1={'/motorService/maintenance.png'}
      />
      <Services
        menu={'MOTOR MAINTENANCE'}
        submenu={'Motor Rewinding '}
        paraExtra={
          "Our Motor Rewinding service is designed to breathe new life into your motors by expertly rewinding their coils. With our extensive knowledge and experience in motor rewinding, we ensure the restoration of optimal functionality and performance. Over time, motors may experience coil failures, insulation breakdown, or other issues that affect their efficiency. Rewinding the motor coils involves replacing damaged or worn-out winding coils, which are essential for generating the electrical output. Our skilled technicians begin by carefully disassembling the motor, inspecting the coils for any signs of damage or degradation. Using precision techniques and high-quality materials, they expertly rewind the coils to meet or exceed industry standards. We ensure precise winding configurations and proper insulation, guaranteeing improved motor performance. Once the rewinding process is complete, our technicians meticulously reassemble the motor, ensuring proper alignment and electrical connections. We conduct thorough tests to verify the rewound motor's performance, ensuring it meets our stringent quality standards."
        }
        paraExtra1={
          'Choosing our Motor Rewinding service offers numerous benefits. It not only saves you the cost of purchasing a new motor but also extends the lifespan of your existing equipment. Additionally, rewound motors exhibit improved energy efficiency and reliability. Experience the benefits of our expertise in motor rewinding. Trust our dedicated team to deliver exceptional service, ensuring the optimal performance and longevity of your motors. Contact us today to discuss your specific motor rewinding needs, and let us provide tailored solutions that meet your requirements.'
        }
        image1={'/motorService/rewinding.png'}
      />
      <Services
        menu={'MOTOR MAINTENANCE'}
        submenu={'Bearings Replacement'}
        paraExtra={
          'Our Bearings Replacement service is designed to address worn-out or damaged bearings in your industrial machinery. As bearings play a critical role in supporting rotating components, their proper functioning is vital to ensure smooth operation and prevent further damage. During our bearings replacement service, our skilled technicians begin by carefully assessing the condition of the bearings. They inspect for signs of wear, misalignment, or inadequate lubrication. By identifying the root cause of bearing failure, we can address the underlying issue and prevent future problems. Next, our technicians expertly remove the old bearings, utilizing appropriate tools and techniques to minimize any potential damage to the surrounding components. We ensure the highest level of precision and care during this process. Once the old bearings are removed, our team selects high-quality replacement bearings that are suitable for your specific machinery and application requirements. We prioritize sourcing bearings from reputable manufacturers to ensure reliability and longevity. During the installation process, our technicians meticulously align and fit the new bearings, paying close attention to proper clearances and lubrication. This guarantees optimal performance and extends the lifespan of your machinery.'
        }
        paraExtra1={
          'By opting for our Bearings Replacement service, you can expect improved equipment reliability, reduced downtime, and enhanced operational efficiency. Our skilled technicians are committed to delivering exceptional results, ensuring your machinery operates at its best. Experience the benefits of our expertise in bearings replacement. Contact us today to discuss your specific needs and let us provide tailored solutions to optimize the performance and longevity of your industrial machinery.'
        }
        image1={'/motorService/bearing.png'}
      />
      <Services
        menu={'MOTOR MAINTENANCE'}
        submenu={'Rotor Balancing'}
        paraExtra={
          'Our Rotor Balancing service focuses on optimizing the performance and reliability of rotating machinery by ensuring precise rotor balance. Imbalanced rotors can result in vibrations, excessive wear and tear, and reduced equipment lifespan. Our skilled technicians possess the expertise and equipment to deliver exceptional rotor balancing solutions. During the rotor balancing process, our technicians carefully assess the existing rotor balance using advanced diagnostic tools. They identify any imbalances that may lead to operational issues or potential damage to the machinery. Using precision techniques, our technicians make precise adjustments to redistribute weight and achieve optimal rotor balance. This involves adding or removing weight strategically to minimize vibrations and ensure smooth operation. We understand the criticality of proper rotor balance in maintaining equipment integrity and maximizing performance. Our technicians meticulously measure and analyze vibration levels, ensuring that the rotor operates within acceptable parameters.'
        }
        paraExtra1={
          'By choosing our Rotor Balancing service, you can expect improved equipment reliability, reduced downtime, and enhanced operational efficiency. Our skilled technicians are committed to delivering exceptional results, ensuring your machinery operates at its best. Experience the benefits of our expertise in rotor balancing. Contact us today to discuss your specific needs, and let us provide tailored solutions to optimize the performance and longevity of your rotating machinery.'
        }
        image1={'/motorService/rotor.png'}
      />
      <Services
        menu={'MOTOR MAINTENANCE'}
        submenu={'Machining of Body and Covers'}
        paraExtra={
          'Our Machining of Body and Covers service offers precise machining solutions for the bodies and covers of your industrial equipment. We understand the importance of maintaining the integrity and functionality of these components, and our skilled technicians are equipped to deliver exceptional machining services. During the machining process, our technicians utilize advanced equipment and techniques to carefully shape, cut, or modify the bodies and covers of your machinery. Whether it involves creating intricate designs, achieving precise measurements, or repairing damaged surfaces, we ensure the highest level of precision and quality. Our technicians have expertise in working with various materials, including metals and alloys, ensuring that we can address the specific requirements of your equipment. They follow industry best practices and standards to guarantee accurate machining results that meet your expectations. We prioritize the durability and longevity of the machined components, taking care to ensure proper fit, alignment, and smooth surfaces. Our meticulous approach ensures that the bodies and covers of your machinery are restored or modified to optimal specifications.'
        }
        paraExtra1={
          "By choosing our Machining of Body and Covers service, you can expect improved equipment performance, enhanced functionality, and extended lifespan. Our skilled technicians are committed to delivering exceptional results, ensuring your machinery operates at its best. Experience the benefits of our expertise in machining. Contact us today to discuss your specific needs, and let us provide tailored solutions to optimize the performance and reliability of your industrial equipment's bodies and covers."
        }
        image1={'/motorService/machining.png'}
      />
    </Box>
  );
};

export default Motormaintainace;
