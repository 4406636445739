import { Box } from '@chakra-ui/react';
import React from 'react';
import { MobileWhatWeOffer } from './MobileWhatWeOffer';
import WhatWeOfferDesktop from './WhatWeOfferDesktop';

export default function WhatWeOffer() {
  return (
    <Box>
      <MobileWhatWeOffer />
      <WhatWeOfferDesktop />
    </Box>
  );
}
