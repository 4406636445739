import { Box, Button, Flex, Image, Text } from '@chakra-ui/react';
import { Link } from '@chakra-ui/react';
import React from 'react';
export default function MobileOfferComponent({
  tittle,
  information,
  img,
  h,
  w,
  pr,
  pb,
}) {
  return (
    <Box
      bgGradient={'white'}
      bg={"white"}
      w={w}
      fontFamily={'Montserrat'}
      borderRadius={'10px'}
      border={"1px solid #DEECEF"}
    >
      <Flex
        flexDir={'column'}
        gap={{ base: '13.15px', sm: '4.109vw' }}
        pl={{ base: '13px', sm: '3.98vw' }}
        pt={{ base: '13px', sm: '3.98vw' }}
        pr={pr}
        pb={pb}
      >
        <Box
          bgColor={'#CFD0E8'}
          width={{ base: '36.5px', sm: '11.40vw' }}
          height={{ base: '36.5px', sm: '11.40vw' }}
          alignItems={'center'}
          display={'flex'}
          justifyContent={'center'}
          borderRadius={'50%'}
        >
          <Image src={img} width={{ base: '20.46px', sm: '6.393vw' }} />
        </Box>
        <Box
          display={'flex'}
          flexDir={'column'}
          gap={{ base: '16px', sm: '5vw' }}
        >
          <Text
            fontFamily={'Montserrat'}
            fontWeight={'600'}
            // fontSize={{ "2xl": "44px", xl: "34px", lg: "24px" }}
            fontSize={{ base: '18.0px', sm: '5.14vw' }}
            // lineHeight={{ "2xl": "52px", xl: "42px", lg: "32px" }}
            lineHeight={{ base: '19px', sm: '5.93vw' }}
            color={'#000938'}
          >
            {tittle}
          </Text>
          <Text
            fontFamily={'Montserrat'}
            fontWeight={'500'}
            // fontSize={{ "2xl": "20px", xl: "16px", lg: "12px" }}
            fontSize={{ base: '12.31px', sm: '3.84vw' }}
            lineHeight={{ base: '19.02px', sm: '5.94vw' }}
            color={'#464E77'}
            letterSpacing={'2%'}
            w={'28ch'}
          >
            {information}
          </Text>
        </Box>
      </Flex>
    </Box>
  );
}
