import { Box, Center } from '@chakra-ui/react';
import React from 'react';
import Titledescr from './Titledescr';

const HeadingPara = () => {
  return (
    <Center>
      <Box
        width={'90%'}
        display={'flex'}
        justifyContent={'space-between'}
        flexDir={'column'}
        gap={'25px'}
        pt={'75px'}
      >
        <Box
          color={'#000938'}
          fontSize={{ lg: '35px', base: '25px' }}
          fontWeight={600}
        >
          Policies
        </Box>

        <Box
          color={'#464E77'}
          fontSize={{ lg: '18px', base: '16px' }}
          fontWeight={400}
          lineHeight={{ lg: '24px' }}
        >
          Sed ut perspiciatis unde omnis iste natus error sit voluptatem
          accusantium doloremque laudantium, totam rem aperiam, eaque ipsa quae
          ab illo inventore veritatis et quasi architecto beatae vitae dicta
          sunt explicabo. Nemo enim ipsam voluptatem quia voluptas sit
          aspernatur aut odit aut fugit, sed quia consequuntur magni dolores eos
          qui ratione voluptatem sequi nesciunt. Neque porro quisquam est, qui
          dolorem ipsum quia dolor sit amet, consectetur, adipisci velit, sed
          quia non numquam eius modi tempora incidunt ut labore et dolore magnam
          aliquam quaerat voluptatem. Ut enim ad minima veniam, quis nostrum
          exercitationem ullam corporis suscipit laboriosam, nisi ut aliquid ex
          ea commodi consequatur? Quis autem vel eum iure reprehenderit qui in
          ea voluptate velit esse quam nihil molestiae consequatur, vel illum
          qui dolorem eum fugiat quo voluptas nulla pariatur Sed ut
          perspiciatis.
        </Box>
        <Titledescr
          headline={'Headline 1'}
          description={
            ' Lorem ipsum dolor sit amet, consectetur adipiscing elit. Sed semper nislnulla habitant aliquam. Vulputate posuere non ullamcorper aliquam sedpretium fusce eget risus. Mauris egestas risus feugiat urna, blandit faucibus fermentum platea gravida. Eget varius nascetur mauris porttitor suspendisse praesent est. Diam nisl magnis massa dignissim volutpat. Placerat mauris suspendisse ut sapien rhoncus accumsan pharetra massa cursus. At egestas id amet donec cursus. Morbi vestibulum, diam mattis vestibulum, lorem sed. Eros, mi quisque integer purus scelerisque dui.  At arcu et arcu, semper ut mollis. Lacus, nulla lacus enim venenatis a. Porttitor id tempor nam tellus orci, cras a. I dictum eimperdiet diam convallis aliquam, quis. Eu interdum nunc morbi'
          }
        />

        <Titledescr
          headline={'Headline 2'}
          description={
            ' Lorem ipsum dolor sit amet, consectetur adipiscing elit. Sed semper nislnulla habitant aliquam. Vulputate posuere non ullamcorper aliquam sedpretium fusce eget risus. Mauris egestas risus feugiat urna, blandit faucibus fermentum platea gravida. Eget varius nascetur mauris porttitor suspendisse praesent est. Diam nisl magnis massa dignissim volutpat. Placerat mauris suspendisse ut sapien rhoncus accumsan pharetra massa cursus. At egestas id amet donec cursus. Morbi vestibulum, diam mattis vestibulum, lorem sed. Eros, mi quisque integer purus scelerisque dui.  At arcu et arcu, semper ut mollis. Lacus, nulla lacus enim venenatis a. Porttitor id tempor nam tellus orci, cras a. I dictum eimperdiet diam convallis aliquam, quis. Eu interdum nunc morbi'
          }
        />
      </Box>
    </Center>
  );
};

export default HeadingPara;
