import { Box, Center, Image, Text } from '@chakra-ui/react';
import React, { useEffect, useState } from 'react';
// import classes from './slider.module.css';
import './slider.css';

const compititiveArr = [
  {
    title: 'Competitive Pricing',
    description:
      'Our services are extremely affordable and competitively priced, offering unbeatable value for your investment.',
    img: '/featuresslide/comp1.png',
  },
  {
    title: 'Countrywide availability for your convenience.',
    description:
      'We have a wide presence throughout Oman with a network of skilled and certified engineers, .',
    img: '/featuresslide/comp2.png',
  },
  {
    title: '24/7 availability for our services.',
    description:
      'We offer flexible service hours, including weekends and outside of regular business hours, at no additional cost.',
    img: '/featuresslide/comp3.png',
  },
  {
    title: 'We exceed requirements.',
    description:
      "You'll receive a detailed PAT report with photos, barcoded labels, and compliance certificate for appliances that pass or fail.",
    img: '/featuresslide/comp4.png',
  },
  {
    title: 'We offer exceptional service regardless of business size.',
    description:
      'We cater to PAT testing needs of all, from small traders to multi-site companies.',
    img: '/featuresslide/comp5.png',
  },
  {
    title: 'We provide a complimentary reminder service.',
    description:
      'Our courtesy reminder service ensures that you never forget to get your electrical equipment tested.',
    img: '/featuresslide/comp6.png',
  },
  {
    title: 'We offer professional guidance whenever required.',
    description:
      'Our commitment to keeping our clients updated with the latest industry trends ensures that they are always well-informed.',
    img: '/featuresslide/comp7.png',
  },
  {
    title: 'We are a one-stop solution for your safety needs.',
    description:
      'Apart from PAT testing, we provide various electrical compliance services to help you meet your safety obligations.',
    img: '/featuresslide/comp8.png',
  },
];

export const FeaturesSlider = () => {
  const items = compititiveArr;
  const [current, setCurrent] = useState(0);
  const [autoPlay, setAutoPlay] = useState(true);
  let timeOut = null;

  useEffect(() => {
    timeOut =
      autoPlay &&
      setTimeout(() => {
        slideRight();
      }, 3500);
  });

  const slideRight = () => {
    setCurrent(current === items.length - 1 ? 0 : current + 1);
  };

  const slideLeft = () => {
    setCurrent(current === 0 ? items.length - 1 : current - 1);
  };
  // console.log(current);

  return (
    <Center w={'full'} bg={'#000938'}>
      <Box
        className="carousel"
        h={{ base: '120vw', md: 'min(33vw,670px)' }}
        // h={{ lg: '34.89vw', md: '33.85vw', '3xl': '650px', base: '80.125vw' }}
        w="100%"
        display={{ base: 'flex', md: 'flex' }}
        flexDir={{ base: 'column-reverse', md: 'row' }}
        // justifyContent="space-between"
        // gap='60px'
        maxW="1920px"
      >
        <Box
          className="carouselText"
          h={{ base: '100vw', md: '100%' }}
          w={{ md: '42%', base: '100%' }}
          onMouseEnter={() => {
            setAutoPlay(false);
            clearTimeout(timeOut);
          }}
          onMouseLeave={() => {
            setAutoPlay(true);
          }}
        >
          <Box
            className="carouselWrapper"
            display="flex"

            // flexDir={{ base: 'column', lg: 'row' }}
          >
            {compititiveArr.map((item, index) => {
              return (
                <Box
                  bg={'#000938'}
                  h={{ base: '80%', md: '100%' }}
                  className={
                    index == current
                      ? 'carousel_card_text carousel_card_text-active'
                      : 'carousel_card_text'
                  }
                >
                  <Box
                    w="100% "
                    padding={{
                      lg: ' 5vw 5vw 5vw 5vw ',
                      md: '5.25vw',
                      '3xl': '126px 64px 64px 64px',
                      base: '9.37vw 2vw 9vw 9vw',
                    }}
                  >
                    <Text
                      fontSize={{
                        '3xl': '45px',
                        lg: '2.3vw',
                        md: '3vw',
                        base: '5.575vw',
                      }}
                      lineHeight={{
                        '3xl': '60px',
                        lg: '3.5vw',
                        md: '4vw',
                        base: '6.5vw',
                      }}
                      fontWeight="500"
                      fontFamily={'Montserrat'}
                    >
                      {item.title}
                    </Text>
                    <Text
                      fontSize={{
                        md: '1.5vw',
                        base: '4.375vw',
                        '2xl': '30px',
                      }}
                      marginTop={{ md: '2.08vw', base: '10px' }}
                      fontWeight="300"
                      fontFamily={'Montserrat'}
                    >
                      {item.description}
                    </Text>
                  </Box>
                  {/* <Image h="100%" w="55%" src={item.img} /> */}
                </Box>
              );
            })}
            <Box
              display={{ base: 'none', md: 'block' }}
              h={{ base: '30px', lg: '150px' }}
              w={'100%'}
              pt={'40px'}
              className="carousel_pagination"
              marginBottom={{ md: '3.645vw', base: '6.25vw' }}
              // position="relative"
              position={{ base: 'relative', md: 'absolute' }}
              bottom={{ base: '-50vw', md: '-110', lg: '-125' }}
              zIndex={'99999'}
              left={{
                // base: '28vw',
                sm: '-20vw',
                sm2: 'vw',
                md: '9.5vw',
                lg: '12vw',
                '2xl': '13.5vw',
                '3xl': '18rem',
              }}

              // top={{ lg: '500', md: '300', base: '220' }}
              // bottom={{ base: '5vw', sm: '4.25vw', md: '30px', xl: '40px' }}
              // left={{
              //   base: '12vw',
              //   sm: '15vw',
              //   xl: '15.62vw',
              //   md: '10.5vw',
              //   '3xl': '400',
              // }}
            >
              {compititiveArr.map((_, index) => {
                return (
                  <div
                    zIndex={'9999'}
                    key={index}
                    className={
                      index == current
                        ? 'pagination_dot pagination_dot-active'
                        : 'pagination_dot'
                    }
                    onClick={() => setCurrent(index)}
                  ></div>
                );
              })}
            </Box>
          </Box>
        </Box>
        {/* images */}
        <Box
          h={{ base: '60vw', md: '100%' }}
          bg={''}
          className="carouselImg"
          alignSelf="flex-end"
          w={{ md: '58%', base: '100%' }}
          onMouseEnter={() => {
            // setAutoPlay(false);
            clearTimeout(timeOut);
          }}
          onMouseLeave={() => {
            setAutoPlay(true);
          }}
        >
          <Box bg={'#000938'} className="carouselWrapper">
            {compititiveArr.map((item, index) => {
              return (
                <Box
                  className={
                    index == current
                      ? 'carousel_card_img carousel_card_img-active'
                      : 'carousel_card_img'
                  }
                  h={'100%'}
                >
                  <Image
                    objectFit={{ md: 'cover', base: 'cover' }}
                    h={{ base: '62vw', md: '100%' }}
                    w="100%"
                    src={item.img}
                    bg={'#000938'}
                    // borderTopRadius={'15px'}
                  />
                </Box>
              );
            })}
          </Box>
        </Box>
      </Box>
    </Center>
  );
};
