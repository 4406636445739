import { Box, Button, Image, Link, Text } from '@chakra-ui/react';
import React from 'react';
import { blogsList } from '../config/blogsData';
import { Helmet } from 'react-helmet';

const CustomButon = ({ blogId }) => {
  return (
    <Link href={`/singleblog/${blogId}`}>
      {' '}
      <Button
        fontSize={{ lg: 'min(1vw,1.2rem)' }}
        variant="ghost"
        border={'1px solid #354A80'}
        py={'min(1.8rem,1.5vw)'}
        fontWeight={'600'}
        color={'#354A80'}
        w={'full'}
      >
        Read More
      </Button>
    </Link>
  );
};

const Blogs = () => {
  // const blogs = [
  //   {
  //     title: 'The Importance of Regular Maintenance for Industrial Generators',
  //     description: [
  //       'Industrial generators are the backbone of many operations, providing a reliable power supply when it matters the most. However, like any complex machinery, generators require regular maintenance to ensure optimal performance and longevity.',

  //       'Preventing Costly Breakdowns: Regular maintenance significantly reduces the risk of unexpected failures by identifying and addressing potential issues before they escalate. Thorough inspections, testing, and component replacements carried out during maintenance visits help keep the generator in top working condition, minimizing downtime and avoiding costly emergency repairs.',

  //       "Maximizing Performance and Efficiency: Factors like dust accumulation, worn-out parts, and fuel system deposits can impact a generator's performance and fuel efficiency over time. Routine maintenance, including cleaning, lubrication, and filter replacements, ensures optimal airflow, proper fuel combustion, and efficient power generation. This not only helps save on fuel costs but also maximizes the generator's output, allowing it to meet the demands of your industrial operations consistently.",

  //       'Extending Generator Lifespan:Regular maintenance plays a critical role in maximizing the lifespan of your industrial generator. By addressing minor issues promptly, conducting regular inspections, and adhering to recommended maintenance schedules, you can significantly extend its lifespan. This not only protects your investment but also reduces the need for premature replacements, saving your business from unnecessary expenses in the long run.',
  //     ],
  //     datenauth: 'Noorullah  |  20 Mar 2023',
  //     image: '/blogs/blogImg1.webp',
  //   },
  //   {
  //     title: 'Top 5 Signs Your Air Conditioner Needs Professional Maintenance',
  //     description: [
  //       'Introduction: An efficiently functioning air conditioner is essential for a comfortable indoor environment, especially during hot summer months. To ensure your air conditioner continues to operate at its best, regular professional maintenance is crucial. In this blog post, we will discuss the top five signs that indicate your air conditioner needs professional maintenance and why timely maintenance is vital for optimal performance.',

  //       "Sign 1: Insufficient Cooling: If you notice that your air conditioner is no longer providing the desired cooling effect, it's a clear indication that professional maintenance is needed. Common causes for insufficient cooling include refrigerant leaks, clogged filters, or a malfunctioning compressor. Professional maintenance can identify and resolve these issues, restoring your air conditioner's cooling capacity.",

  //       "Sign 2: Poor Airflow: A significant decrease in airflow from your air conditioner's vents is another sign that maintenance is required. Obstructed air filters, blocked ductwork, or faulty fan motors can all contribute to poor airflow. Professional maintenance will address these problems, ensuring proper airflow and efficient cooling throughout your space.",

  //       'Sign 3: Unusual Noises: Unusual noises such as rattling, grinding, or squealing coming from your air conditioner can indicate mechanical problems. Loose components, worn-out belts, or damaged fan blades may be the culprits. Ignoring these noises can lead to further damage. Professional maintenance will identify and resolve these issues promptly, preventing costly repairs down the line.',

  //       'Sign 4: Frequent Cycling On and Off: If your air conditioner is cycling on and off too frequently, it may be struggling to maintain the desired temperature. This issue, known as short cycling, can occur due to various factors such as a dirty condenser coil, a malfunctioning thermostat, or an oversized unit. Professional maintenance will diagnose the underlying cause and restore the proper functioning of your air conditioner.',

  //       'Sign 5: High Energy Bills: A sudden spike in your energy bills without any significant changes in usage can be a sign of an inefficient air conditioner. Issues like dirty coils, improper refrigerant levels, or faulty electrical components can cause your air conditioner to work harder, consuming more energy. Regular maintenance will optimize the efficiency of your unit, reducing energy consumption and lowering your bills.',

  //       'Conclusion: Regular professional maintenance is essential for the optimal performance of your air conditioner. By addressing signs of insufficient cooling, poor airflow, unusual noises, frequent cycling, and high energy bills, you can prevent costly repairs and extend the lifespan of your unit. At [Your Company Name], our experienced technicians specialize in air conditioner maintenance, ensuring your system operates at its peak efficiency. Schedule a maintenance visit today and enjoy a cool and comfortable indoor environment all summer long.',
  //     ],
  //     datenauth: 'Noorullah  |  20 Mar 2023',
  //     image: '/blogs/blogImg2.webp',
  //   },
  //   {
  //     title: 'Understanding the Different Types of Bulldozer Maintenance',
  //     description: [
  //       'Introduction: Bulldozers play a crucial role in construction and earthmoving projects, making it essential to understand the different types of maintenance they require. By implementing proper maintenance practices, you can ensure the optimal performance, reliability, and longevity of these heavy-duty machines. In this blog post, we will explore the various types of bulldozer maintenance and their significance in keeping them in top working condition.',

  //       'Preventive Maintenance: Preventive maintenance is the cornerstone of keeping bulldozers running smoothly. It involves regular inspections, servicing, and component replacements to prevent breakdowns and identify potential issues before they escalate. Tasks like checking fluid levels, inspecting undercarriage components, lubricating moving parts, and cleaning air filters are all part of preventive maintenance. By following a preventive maintenance schedule, you can enhance the reliability and lifespan of your bulldozer while minimizing costly repairs.',

  //       "Scheduled Maintenance: Scheduled maintenance is based on the manufacturer's recommendations for maintenance intervals, typically determined by operating hours or time. This type of maintenance includes more extensive tasks, such as engine and transmission inspections, hydraulic system checks, and electrical system diagnostics. Following the manufacturer's guidelines for scheduled maintenance helps ensure that your bulldozer operates at peak performance, minimizing unexpected failures and maximizing productivity.",

  //       'Breakdown Maintenance: Despite regular preventive and scheduled maintenance, breakdowns may still occur. Breakdown maintenance involves addressing unexpected failures and malfunctions promptly. This type of maintenance requires quick response times to minimize downtime and get the bulldozer back in operation efficiently. Timely troubleshooting, diagnostics, and repairs by experienced technicians are crucial in this scenario.',

  //       'Component Overhaul: Over time, certain components of a bulldozer may require complete overhauls due to wear and tear. Component overhauls involve disassembling, inspecting, and rebuilding major components like engines, transmissions, and hydraulic systems. This maintenance type is typically performed at specified intervals or when specific indicators, such as decreased performance or excessive wear, are observed. Properly executed component overhauls restore the functionality and reliability of critical systems, extending the overall lifespan of the bulldozer.',
  //     ],
  //     datenauth: 'Noorullah  |  20 Mar 2023',
  //     image: '/blogs/blogImg3.webp',
  //   },
  // ];

  // const allblogs = [
  //   {
  //     title: '10 Tips for Extending the Lifespan of Your Industrial Equipment',
  //     description: [
  //       'Introduction: Industrial equipment plays a vital role in the productivity and success of businesses across various sectors. To ensure maximum return on investment and optimal performance, it is crucial to extend the lifespan of your industrial equipment. In this blog post, we will share ten valuable tips to help you prolong the lifespan of your industrial equipment and minimize the need for premature replacements.',

  //       "Follow Manufacturer's Guidelines: Always adhere to the manufacturer's guidelines for operation, maintenance, and recommended service intervals. These guidelines are specifically designed to ensure the equipment operates efficiently and safely throughout its lifespan.",

  //       'Implement Regular Maintenance: Develop a proactive maintenance plan that includes regular inspections, cleaning, lubrication, and component replacements. Routine maintenance helps identify and address potential issues before they escalate, preventing costly breakdowns and extending equipment lifespan.',

  //       'Train Your Operators: Properly trained operators can significantly impact the lifespan of industrial equipment. Ensure your operators are well-trained in operating procedures, safety protocols, and best practices for equipment usage. This reduces the risk of operator-induced damage and promotes efficient and safe operation.',

  //       'Monitor Operating Conditions: Regularly monitor operating conditions such as temperature, vibration, and fluid levels. Deviations from normal operating parameters can indicate underlying issues that need to be addressed promptly to prevent further damage.',

  //       'Keep Equipment Clean: Regularly clean your industrial equipment to remove dust, debris, and contaminants that can cause component wear and hinder performance. Clean equipment operates more efficiently and is less prone to breakdowns.',

  //       'Use Genuine Parts and Lubricants: When replacing components or lubricating your equipment, always use genuine parts and recommended lubricants. Generic or substandard parts and lubricants can compromise performance and reduce the lifespan of your equipment.',

  //       "Address Issues Promptly: Don't ignore signs of equipment malfunction or abnormal operation. Address any issues promptly by scheduling repairs or consulting with qualified technicians. Timely intervention can prevent small problems from escalating into major failures.",

  //       'Proper Storage: If your equipment is not in use for an extended period, ensure it is stored in a clean, dry, and secure environment. Protecting equipment from harsh weather conditions and potential vandalism or theft can help extend its lifespan.',

  //       'Train Maintenance Staff: Invest in training your maintenance staff to ensure they have the necessary skills and knowledge to perform maintenance tasks effectively. Proper maintenance procedures, including troubleshooting and repair techniques, can help prolong equipment lifespan.',

  //       'Track and Analyze Performance: Implement a system to track and analyze equipment performance over time. This includes monitoring energy consumption, maintenance costs, and downtime. By analyzing performance data, you can identify areas for improvement and take proactive measures to extend equipment lifespan.',
  //     ],
  //     datenauth: 'Noorullah  |  20 Mar 2023',
  //     image: '/blogs/blogImg4.webp',
  //   },
  //   {
  //     title: 'The Role of Proper Lubrication in Equipment Maintenance',
  //     description:
  //       'Proper lubrication is a critical factor in maintaining the performance, efficiency, and longevity of industrial equipment. Lubricants...',
  //     datenauth: 'Noorullah  |  20 Mar 2023',
  //     image: '/blogs/blogImg5.webp',
  //   },
  //   {
  //     title:
  //       'Emergency Maintenance: Minimizing Downtime in Industrial Operations',
  //     description:
  //       'In industrial operations, unexpected equipment failures can lead to significant downtime, resulting in productivity losses and increased...',
  //     datenauth: 'Noorullah  |  20 Mar 2023',
  //     image: '/blogs/blogImg6.webp',
  //   },
  //   {
  //     title: 'A Comprehensive Guide to Industrial Equipment Inspections',
  //     description:
  //       'Regular equipment inspections are crucial for maintaining the performance, reliability, and safety of industrial machinery. Proper...',
  //     datenauth: 'Noorullah  |  20 Mar 2023',
  //     image: '/blogs/blogImg7.webp',
  //   },
  //   {
  //     title:
  //       'The Benefits of Preventive Maintenance for Industrial Air Compressors',
  //     description:
  //       'Industrial air compressors play a critical role in various industries, providing a reliable source of compressed air for numerous applications...',
  //     datenauth: 'Noorullah  |  20 Mar 2023',
  //     image: '/blogs/blogImg8.webp',
  //   },
  //   {
  //     title:
  //       'Common Maintenance Mistakes to Avoid for Industrial Equipment Owners',
  //     description:
  //       'Proper maintenance is crucial for the performance, reliability, and lifespan of industrial equipment. However, certain maintenance...',
  //     datenauth: 'Noorullah  |  20 Mar 2023',
  //     image: '/blogs/blogImg9.webp',
  //   },
  //   {
  //     title:
  //       'Why Partnering with a Maintenance Service Provider Makes Sense      ',
  //     description:
  //       'Efficient and reliable maintenance is essential for the smooth operation of industrial equipment. However, managing maintenance... ',
  //     datenauth: 'Noorullah  |  20 Mar 2023',
  //     image: '/blogs/blogImg10.webp',
  //   },
  //   {
  //     title:
  //       'Industrial Equipment Maintenance: In-House vs. Outsourcing Pros and Cons',
  //     description:
  //       'Maintaining industrial equipment is vital for ensuring optimal performance, reliability, and safety in various industries. When it...',
  //     datenauth: 'Noorullah  |  20 Mar 2023',
  //     image: '/blogs/blogImg11.webp',
  //   },
  // ];

  return (
    <Box
      bgColor={'#F4F9FF'}
      width={'full'}
      display={'flex'}
      justifyContent={'center'}
    >
      <Helmet>
        <title>Blogs | ECIWS</title>
      </Helmet>
      <Box
        width={{ lg: '90%' }}
        maxW={'1920px'}
        display={'flex'}
        flexDir={'column'}
        py={'3rem'}
      >
        <Text
          fontSize={{ base: '1.4rem', lg: 'min(2vw,2.5rem)' }}
          color={'#000938'}
          fontWeight={'500'}
          fontFamily={'Montserrat'}
          textAlign={{ lg: 'start', base: 'center' }}
          mb={'2rem'}
        >
          Recent Blog Posts
        </Text>
        {/* grid */}
        <Box
          display={{ base: 'flex', lg: 'grid' }}
          gridTemplateColumns={'1.2fr 1fr'}
          gridTemplateRows={'1fr 1fr'}
          flexDirection={'column'}
          gap={{ lg: '2rem', base: '3rem' }}
        >
          {/* main blog */}
          <Box
            gridColumnStart={'1'}
            gridColumnEnd={'2'}
            gridRowStart={'1'}
            gridRowEnd={'3'}
            display={'flex'}
            flexDir={'column'}
            w={'full'}
            gap={'0.5rem'}
            mr={{ lg: '1rem' }}
            alignItems={{ base: 'center', lg: 'start' }}
          >
            <Image
              w={'full'}
              h={{ base: '15rem', lg: '25rem' }}
              objectFit={'cover'}
              src={blogsList[0].image}
              borderRadius={{ lg: '1rem' }}
            />
            <Text
              fontSize={{ base: '0.8rem', lg: 'min(1vw,1.3rem)' }}
              mt={{ base: '1rem', lg: '2.5rem' }}
              fontFamily={'Montserrat'}
            >
              {blogsList[0].datenauth}
            </Text>
            <Box
              display={'flex'}
              w={{ base: '100%', lg: 'full' }}
              justifyContent={{ base: 'center', lg: 'space-between' }}
            >
              {' '}
              <Text
                fontWeight={'600'}
                lineHeight={'1.8rem'}
                pb={'1rem'}
                textAlign={{ lg: 'start', base: 'center' }}
                fontSize={{ base: '1.35rem', lg: 'min(1.8vw,2rem)' }}
              >
                {blogsList[0].title}
              </Text>
              <Link href="/singleblog/1">
                <Image
                  display={{ base: 'none', lg: 'flex' }}
                  w={'20px'}
                  src="/blogs/arrow.svg"
                  mt={'1rem'}
                />
              </Link>
            </Box>
            <Text
              className="threeline"
              fontSize={{ base: '1rem', lg: 'min(1.3vw,1.5rem)' }}
              textAlign={{ lg: 'start', base: 'center' }}
              width={{ base: '90%', lg: '76%' }}
            >
              {blogsList[0].description}
            </Text>
            <Box
              display={{ lg: 'none', base: 'block' }}
              w={'10rem'}
              mt={'1rem'}
            >
              <CustomButon blogId={'1'} />
            </Box>
          </Box>

          {/* 2 second blogs */}
          <Box
            gridColumnStart={'2'}
            gridColumnEnd={'3'}
            gridRowStart={'1'}
            gridRowEnd={'2'}
            display={'flex'}
            flexDir={{ lg: 'row', base: 'column' }}
            gap={'1.5rem'}
            h={{ lg: '21rem' }}
            w={'full'}
            alignItems={{ base: 'center', lg: 'start' }}
          >
            <Image
              src={blogsList[1].image}
              w={{ base: 'full', lg: 'min(22vw,22rem)' }}
              borderRadius={{ lg: '1rem' }}
              objectFit={'cover'}
              h={{ base: '20rem', lg: 'full' }}
            />
            <Box
              px={{ base: '6px', lg: '0px' }}
              py={{ lg: 'min(2vw,2rem)' }}
              display={'flex'}
              flexDir={'column'}
              w={{ base: '95%', lg: 'full' }}
              gap={'0.5rem'}
              alignItems={{ base: 'center', lg: 'start' }}
            >
              <Text
                fontSize={{ base: '0.8rem', lg: 'min(0.8vw,1.1rem)' }}
                textAlign={{ base: 'center', lg: 'start' }}
                fontFamily={'Montserrat'}
              >
                {blogsList[1].datenauth}
              </Text>
              <Text
                fontWeight={'600'}
                fontSize={{ base: '1.2rem', lg: 'min(1.3vw,1.5rem)' }}
                textAlign={{ base: 'center', lg: 'start' }}
                fontFamily={'Montserrat'}
              >
                {blogsList[1].title}
              </Text>
              <Text
                fontSize={{ lg: 'min(0.8vw,1rem)' }}
                w={{ lg: '80%' }}
                textAlign={{ base: 'center', lg: 'start' }}
                className="threeline"
              >
                {blogsList[1].description}
              </Text>
              <Box w={{ base: '7rem', lg: 'min(10rem,10vw)' }} pt={'1rem'}>
                {' '}
                <CustomButon blogId={'2'} />
              </Box>
            </Box>
          </Box>
          <Box
            gridColumnStart={'2'}
            gridColumnEnd={'3'}
            gridRowStart={'2'}
            gridRowEnd={'3'}
            display={'flex'}
            flexDir={{ lg: 'row', base: 'column' }}
            gap={'1.5rem'}
            w={'full'}
            h={{ lg: '21rem' }}
            alignItems={{ base: 'center', lg: 'start' }}
          >
            <Image
              src={blogsList[2].image}
              w={{ base: 'full', lg: 'min(22vw,22rem)' }}
              borderRadius={{ lg: '1rem' }}
              objectFit={'cover'}
              h={{ base: '20rem', lg: 'full' }}
            />
            <Box
              px={{ base: '6px', lg: '0px' }}
              py={{ lg: 'min(2vw,2rem)' }}
              display={'flex'}
              w={{ base: '95%', lg: 'full' }}
              flexDir={'column'}
              gap={'0.5rem'}
              alignItems={{ base: 'center', lg: 'start' }}
            >
              <Text
                fontSize={{ base: '0.8rem', lg: 'min(0.8vw,1.1rem)' }}
                textAlign={{ base: 'center', lg: 'start' }}
              >
                {blogsList[2].datenauth}
              </Text>
              <Text
                fontWeight={'600'}
                fontSize={{ base: '1.2rem', lg: 'min(1.3vw,1.5rem)' }}
                textAlign={{ base: 'center', lg: 'start' }}
                fontFamily={'Montserrat'}
              >
                {blogsList[2].title}
              </Text>
              <Text
                fontSize={{ lg: 'min(0.8vw,1rem)' }}
                w={{ lg: '80%' }}
                textAlign={{ base: 'center', lg: 'start' }}
                className="threeline"
              >
                {blogsList[2].description}
              </Text>
              <Box w={{ base: '7rem', lg: 'min(10rem,10vw)' }} pt={'1rem'}>
                {' '}
                <CustomButon blogId="3" />
              </Box>
            </Box>
          </Box>
        </Box>
        {/* all blog post */}

        <Text
          fontSize={{ base: '1.5rem', lg: 'min(2vw,2.5rem)' }}
          textAlign={{ lg: 'start', base: 'center' }}
          fontFamily={'Montserrat'}
          fontWeight={'500'}
          my={{ base: '4rem', lg: '2rem' }}
        >
          All Blog Posts
        </Text>
        <Box
          display={{ lg: 'grid', base: 'flex' }}
          flexDir={'column'}
          // bgColor={'blue'}
          mt={{ base: '-2rem', lg: '0rem' }}
          px={{ base: '1.5rem', lg: '0rem' }}
          gridTemplateColumns={'1fr 1fr 1fr 1fr'}
          alignItems={{ base: 'center', lg: 'start' }}
          gap={'min(2rem,2vw)'}
        >
          {blogsList.map((data, index) => {
            return (
              <Box
                h={'fit'}
                // minH={'30rem'}
                mb={{ base: '1rem', lg: '0rem' }}
                bg={'white'}
                borderRadius={'1rem'}
                w={{ base: '95%', lg: 'full' }}
                px={{ base: '1rem', lg: 'min(1.5vw,1.8rem)' }}
                py={{ base: '1rem', lg: 'min(1vw,1.2rem)' }}
                key={index}
                display={'flex'}
                flexDir={'column'}
                justifyContent={'space-between'}
                alignItems={{ base: 'center', lg: 'start' }}
              >
                <Image
                  src={data.image}
                  w={'full'}
                  height={'14rem'}
                  borderRadius={'1rem'}
                  objectFit={'cover'}
                />
                <Box
                  my={{ base: '1rem', lg: '2rem' }}
                  minH={{ lg: '11rem', base: '0px' }}
                  display={'flex'}
                  flexDir={'column'}
                  gap={'0.7rem'}
                >
                  <Text
                    textAlign={{ lg: 'start', base: 'center' }}
                    fontSize={{ base: '0.8rem', lg: 'min(0.7vw,0.9rem)' }}
                    fontWeight={{ base: '300', lg: '400' }}
                  >
                    {data.datenauth}
                  </Text>
                  <Text
                    flex={1}
                    textAlign={{ lg: 'start', base: 'center' }}
                    fontWeight={{ lg: '600', base: '500' }}
                    fontSize={{ base: '1rem', lg: 'min(1.1vw,1.3rem)' }}
                    fontFamily={'Montserrat'}
                  >
                    {data.title}
                  </Text>
                  <Text
                    fontWeight={{ lg: '600', base: '500' }}
                    className="threeline"
                    fontFamily={'Source Sans Pro'}
                    color={'#464E77'}
                    textAlign={{ lg: 'start', base: 'center' }}
                    lineHeight={{ base: '1rem' }}
                    fontSize={{ base: '0.9rem', lg: 'min(0.8vw,1rem)' }}
                    // w={{ lg: '80%' }}
                  >
                    {data.description}
                  </Text>
                </Box>{' '}
                <Box w={{ base: 'full', lg: 'full' }}>
                  {' '}
                  <CustomButon blogId={data.id} />
                </Box>
              </Box>
            );
          })}
        </Box>
      </Box>
    </Box>
  );
};

export default Blogs;
